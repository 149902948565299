import { Typography } from "@mui/material";
import { WashupHeader, WashupHeaderSlim } from "../../_components/header";
import { WashupElevatedButton } from "apps/_components/button";
import { useNavigate } from "react-router-dom";
import React from "react";
import ReactHtmlParser from 'react-html-parser';

const LaundryHeader = (props) => {
    const navigate = useNavigate();
    return (
        <WashupHeaderSlim>
            <div className='flex gap-2 items-center'>
                <img className='w-32 h-32 md:w-40 md:h-40' src={props.laundry.icon_url} alt={props.laundry.name} />
                <div className="flex flex-col gap-2 items-start">
                    <Typography variant='h5' className="block md:hidden mb-0">{props.laundry.name }</Typography>
                    <Typography variant='h4' className="hidden md:block mb-0">{props.laundry.name }</Typography>
                    <Typography variant='body2' className="hidden md:block px-4">{ ReactHtmlParser(props.laundry.description) }</Typography>
                    <Typography variant='body2' className="block md:hidden px-4">{ ReactHtmlParser(props.laundry.description) }</Typography>
                    { (!props.hideLink) && (
                        <WashupElevatedButton onClick={() => navigate("/laundry/" + props.laundry.slug)}>Vai alla lavanderia</WashupElevatedButton>
                    )}
                </div>
            </div>
        </WashupHeaderSlim>
    )
}
export default LaundryHeader;