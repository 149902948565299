import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';


export const WashupElevatedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#037abb"),
    backgroundColor: "#037abb",
    "&:disabled":{
        backgroundColor: " #CCCCCC",
    },
    '&:hover': {
      backgroundColor: "#1298d3",
    },
    borderRadius: 100,
    paddingLeft: 40,
    paddingRight: 40,
}));

export const WashupOutlinedButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#FFFFFF"),
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "#1298d3",
      color: "#FFFFFF",
    },
    paddingLeft: 40,
    paddingRight: 40,

    border: "solid 1px #1298d3",
    borderRadius: 100,
}));