import { Card, CardContent, TextField } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import { useContext, useState } from "react";
import WashupContext from "../../../../providers/WashupProvider";
import Loading from "apps/_components/loading";
import React from "react";


const CartStepInfos = (props) => {
    const { cart, setOrderInfos } = useContext(WashupContext);

    const [isLoading, setIsLoading] = useState(false);
    const [infos, setInfos] = useState(props.cart.additionalInfos);

    const changeStep = async (offset) => {
        if(infos !== props.cart.additionalInfos){
            setIsLoading(true);
            await setOrderInfos(cart, infos);
            setIsLoading(false);
        }
        props.setActiveStep(offset)
    }

    return (
        <>
            <Card className="mt-4">
                {
                    (isLoading) ? (
                        <CardContent>
                            <Loading />
                        </CardContent>
                    ) : (
                        <CardContent>
                            <TextField
                                className='w-full'
                                label='Eventuali note aggiuntive per ritiro e/o riconsegna'
                                variant="standard"
                                multiline
                                rows={4}
                                defaultValue={props.cart.additionalInfos}
                                onChange={(event) => setInfos(event.target.value)}
                                InputLabelProps={{style: {}, shrink: true}}
                            />
                        </CardContent>
                    )
                }
            </Card>
            <div className='flex justify-end mt-8 gap-4'>
                <WashupOutlinedButton onClick={() => changeStep(props.activeStep - 1)}>Indietro</WashupOutlinedButton>
                <WashupElevatedButton onClick={() => changeStep(props.activeStep + 1)}>Avanti</WashupElevatedButton>
            </div>
        </>
    );
}
export default CartStepInfos;