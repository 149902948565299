// @ts-nocheck
import { Chip } from "@mui/material";
import React from "react";

export const OrderStatusChip = (props) => {

    const status = props.status;

    var color = null;
    switch(status.color){
        case "":
            color = "rgb(101 163 13";
            break;
        case "secondary":
            color = "rgb(75 85 99)";
            break;
        case "amber":
            color = "rgb(234 88 12)";
            break;
        case "red":
            color = "rgb(220 38 38)";
            break;
        default:
            color = "rgb(75 85 99)";
            break;
    }


    switch(props.size){
        case "s":
            return (
                <div className={"w-full rounded-full text-" + color + " border border-" + color + " py-2 px-4 leading-2" } style={{ color: color, borderColor: color, fontSize: "80%" }}>
                    {status.label}
                </div>
            )
        default:
            return (
                <div className={"rounded-full text-" + color + " border border-" + color + " py-4 px-8 leading-4" } style={{ color: color, borderColor: color }}>
                    {status.label}
                </div>
            )
    }
    return (
        <Chip label={status.label} color={color} variant="outlined" />
    )

};