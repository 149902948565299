import { Typography } from "@mui/material";
import { SearchOff } from '@mui/icons-material';

const Empty = (props) => {
 
    return (
        <div className="w-full p-12 flex flex-col gap-2 items-center opacity-50	">
            <SearchOff fontSize="large" />
            <Typography variant="subtitle1">
                {props.children}
            </Typography>
        </div>
    );
}

export default Empty;