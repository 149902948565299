class UserAddress{

    constructor(data){
        this.id = data.id;
        this.address = data.address;
        this.city = data.city;
        this.postalCode = data.postal_code;
        this.province = data.province;
    }
}
export default UserAddress;
