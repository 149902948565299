// @ts-nocheck
import React, { createContext, useEffect, useState } from "react";
import { apiAddOrderEntry, apiCreateCart, apiCreateGuestCart, apiGetCart, apiGetCoupon, apiGetLaundry, apiGetPages, apiGetUser, apiLogin, apiRegister, apiRemoveOrderEntry, apiSetOrderAdditionalInfos, apiSetOrderAddress, apiSetOrderCoupon } from "../services/api";
import Order from "_models/order";
import UserAddress from "_models/user-address";


const WashupContext = createContext(null);

export const WashupProvider = (props) => {
  const [ user, setUser ] = useState();
  const [ cart, setCart ] = useState();
  const [ showDrawer, setShowDrawer ] = useState(false);
  const [ showMenuDrawer, setShowMenuDrawer ] = useState(false);
  const [ showDiscardCartModal, setShowDiscardCartModal ] = useState(false);
  const [ showLoginModal, setShowLoginModal ] = useState();
  const [ pages, setPages ] = useState();

  const localStorageTokenKey = "washupUserToken";
  const localStoragecartKey = "washupUserCart";


  const register = async (name, email, mobile, password, passwordConfirmation) => {
    var res_token = await apiRegister(name, email, mobile, password, passwordConfirmation);
    if(res_token !== undefined){
      localStorage.setItem(localStorageTokenKey, res_token);
      await getUser();

      return true;
    }
    return false;
  }

  const login = async (username, password) => {
    var res_token = await apiLogin(username, password);
    if(res_token !== undefined){
      localStorage.setItem(localStorageTokenKey, res_token);
      await getUser();

      return true;
    }
    return false;
  }

  const logout = async () => {
    localStorage.removeItem(localStorageTokenKey);
    await getUser();
  }

  const getUser = async () => {
    var res_user = await apiGetUser();
    setUser(res_user);
  }

  const getCart = async () => {
    var localCart = await getLocalCart();
        
    //console.log("getting cart");

    if(user == null){

      setCart(localCart);

    }else{
      //var userCart = await apiGetCart();

      if(localCart != null){
        
        //console.log("overwriting cart");

        var userCart = await createCart(localCart.laundry);
        for (const e of localCart.entries) {
          await apiAddOrderEntry(userCart, e.item, e.quantity, e.note);
        }
        await apiSetOrderAdditionalInfos(userCart, localCart.additionalInfos);
        await apiSetOrderCoupon(userCart, localCart.coupon?.code);

        await deleteLocalCart();
      }

      setCart(await apiGetCart());
    }
  }


  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const createLocalCart = async (laundry) => {
    var data = {
      guest_id: makeid(32),
      laundry: await apiGetLaundry(laundry.id),
      entries: [],
    };
    return data;
  }

  const deleteLocalCart = async () => {
    localStorage.removeItem(localStoragecartKey);
  }

  const getLocalCart = async () => {
    var data = JSON.parse(localStorage.getItem(localStoragecartKey));
    if(data != null){
      var local_cart = new Order(data);
      local_cart.guest_id = data.guest_id;
      local_cart.laundry = await apiGetLaundry(data.laundry_id);
      for (let i = 0; i < data.entries.length; i++) {
        local_cart.entries[i].item = local_cart.laundry.items.find((e) => e.item.id === data.entries[i].item_id).item;
      }
      if(data.pickupAddress){
        local_cart.pickupAddress = new UserAddress(data.pickupAddress);
      }
      if(data.deliveryAddress){
        local_cart.deliveryAddress = new UserAddress(data.deliveryAddress);
      }
      local_cart.additionalInfos = data.additionalInfos;
      local_cart.user = data.user;
      local_cart.coupon = data.coupon;
      return local_cart;
    }else{
      return null;
    }
  }

  const saveLocalCart = async (localCart) => {
    var data = {
      guest_id: localCart.guest_id ?? makeid(32),
      laundry_id: localCart.laundry.id,
      entries: localCart.entries.map((e) => {
        return {
          item_id: e.item?.id ?? e.item_id,
          quantity: e.quantity,
          note: e.note,
        };
      }),
      pickupAddress: {
        address: localCart.pickupAddress?.address,
        postal_code: localCart.pickupAddress?.postalCode,
        city: localCart.pickupAddress?.city,
        province: localCart.pickupAddress?.province,
      },
      deliveryAddress: {
        address: localCart.deliveryAddress?.address,
        postal_code: localCart.deliveryAddress?.postalCode,
        city: localCart.deliveryAddress?.city,
        province: localCart.deliveryAddress?.province,
      },
      additionalInfos: localCart.additionalInfos,
      user: localCart.user,
      coupon: {
        code: localCart.coupon?.code,
        amount: localCart.coupon?.amount,
        percentage: localCart.coupon?.percentage,
        uses: localCart.coupon?.uses,
      }
    };
    localStorage.setItem(localStoragecartKey, JSON.stringify(data));
    await getCart();
  }


  const createCart = async (laundry) => {
    if(user == null){
      return await createLocalCart(laundry);
    }else{
      var res_cart = await apiCreateCart(laundry);
      //await getCart();
      return res_cart;
    }
  }

  const createGuestCart = async () => {
    var data = JSON.parse(localStorage.getItem(localStoragecartKey));
    var guestCart = await apiCreateGuestCart(data);
    return guestCart;
  }


  const addOrderEntry = async(laundry, order, item, quantity, note) => {

    if(note === "") note = null;

    if(order == null || order.laundry.id !== laundry.id){
      order = await createCart(laundry);
    }  
    if(user == null){
      var itemAdded = false;
      for (let i = 0; i < order.entries.length; i++) {
        if(order.entries[i].item.id === item.id){
          order.entries[i].quantity = quantity;
          order.entries[i].note = note;
          itemAdded = true;
        }
      }
      if(!itemAdded){
        order.entries.push({
          item_id: item.id,
          quantity: quantity,
          note: note,
        });
      }
      await saveLocalCart(order);
    }else{
      await apiAddOrderEntry(order, item, quantity, note);
    }
    await getCart();
  }

  const removeOrderEntry = async(order, item) => { 
    if(user == null){
      order.entries = order.entries.filter((e) => e.item.id !== item.id);
      await saveLocalCart(order);
    }else{
      await apiRemoveOrderEntry(order, item);
      await getCart();
    }
  }

  const setOrderAddress = async (order, type, address) => {
    if(user == null) {
      if(type === 'pickup'){
        order.pickupAddress = address;
      }else{
        order.deliveryAddress = address;
      }
      await saveLocalCart(order);
    }else{
      await apiSetOrderAddress(order, type, address);
      await getUser();
    }
    await getCart();
  }

  const setOrderInfos = async (order, notes) => {
    if(user == null) {
      order.additionalInfos = notes;
      await saveLocalCart(order);
    }else{
      await apiSetOrderAdditionalInfos(order, notes);
    }
    await getCart();
  }

  const setOrderUserData = async (order, firstName, lastName, email, mobile) => {
    if(user == null) {
      order.user = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile: mobile,
      };
      await saveLocalCart(order);
    }else{
      //await apiSetOrderAdditionalInfos(order, notes);
    }
    await getCart();
  }

  const setOrderCoupon = async (order, code) => {
    if(code == null){
      if(user == null) {
        order.coupon = null;
        await saveLocalCart(order);
      }else{
        await apiSetOrderCoupon(order, null);
      }
      await getCart();
      return true;
    }

    var coupon = await apiGetCoupon(code);
    //console.log(coupon);
    if(coupon){
      if(user == null) {
        order.coupon = {
          code: coupon.code,
          amount: coupon.amount,
          percentage: coupon.percentage,
          uses: coupon.uses,
        };
        await saveLocalCart(order);
      }else{
        await apiSetOrderCoupon(order, coupon.code);
      }
      await getCart();
      return true;
    }
    return false;
  }

  /* PAGES */

  const getPages = async () => {
    var res_pages = await apiGetPages();
    setPages(res_pages);
  }

  useEffect(() =>  {
    getUser();
    getPages();
  },[]);

  useEffect(() =>  {
    getCart();
  },[user]);

  return (
    <WashupContext.Provider value={{ 
        user, getUser, register, login, logout, 
        showDrawer, setShowDrawer, 
        showMenuDrawer, setShowMenuDrawer, 
        showLoginModal, setShowLoginModal, 
        showDiscardCartModal, setShowDiscardCartModal, 
        cart, getCart, createGuestCart, deleteLocalCart,
        addOrderEntry, removeOrderEntry, setOrderAddress, setOrderInfos, setOrderUserData, setOrderCoupon,
        pages,
      }}>
      {props.children}
    </WashupContext.Provider>
  );
};

export default WashupContext;