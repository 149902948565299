// @ts-nocheck
import { Alert, Card, CardContent, IconButton, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import Loading from "apps/_components/loading";
import WashupContext from "providers/WashupProvider";
import { useContext, useState } from "react";
import React from "react";
import { Edit } from '@mui/icons-material';
import { apiUpdateUserPassword } from "services/api";


const UserProfilePassword = (props) => {
    const { user, getUser } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [ oldPassword, setOldPassword ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ passwordConfirmation, setPasswordConfirmation ] = useState("");

    const [ errors, setErrors ] = useState();
    const [ failed, setFailed ] = useState(false);
    const [ failureReason, setFailureReason ] = useState(false);

    const passwordRegexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;


    const startEdit = () => {
        setOldPassword("");
        setPassword("");
        setPasswordConfirmation("");
        
        setIsEditing(true);
    }

    const tryUpdate = async () => {

        setErrors();
        setFailed(false);
        setFailureReason();

        var validation = {};
        var isValid = true;
        if(password == null || !passwordRegexp.test(password)){
            validation.password = "La password dev'essere di almeno 6 caratteri e deve contenere almeno una lettera, un numero e un carattere speciale";
            isValid = false;
        }
        if(passwordConfirmation !== password){
            validation.passwordConfirmation = "La Password di conferma non corrisponde";
            isValid = false;
        }
        if(isValid){
            setIsLoading(true);
            var result = await apiUpdateUserPassword(oldPassword, password, passwordConfirmation);
            if(result === true){
                await getUser();
                setIsEditing(false);
            }else{
                setFailed(true);
                setFailureReason(result);
            }
            setIsLoading(false);
        }else{
            setErrors(validation);
        }
    }

    if(user == null){
        return <></>;
    }

    return (
        <>
            <Card className="mt-4">
                {
                    (isLoading) ? (
                        <CardContent>
                            <div className="p-8">
                                <Loading />
                            </div>
                        </CardContent>
                    ) : (
                        <CardContent>
                            {!isEditing ? (
                                <div className="flex gap-2">
                                    <div className='flex flex-col gap-2 flex-grow'>
                                        <Typography variant='subtitle1'>Password: ********</Typography>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <IconButton aria-label="edit" onClick={() => startEdit()} >
                                            <Edit />
                                        </IconButton>
                                    </div>
                                </div>
                            ):(
                                <div className="flex flex-col gap-4">
                                    <TextField
                                        className='col-span-2 md:col-span-1'
                                        name="oldPassword"
                                        label='Vecchia password*'
                                        variant="standard"
                                        type="password"
                                        value={oldPassword}
                                        onChange={(event) => setOldPassword(event.target.value)}
        
                                        error={errors?.oldPassword != null}
                                        helperText={errors?.oldPassword}
                                    />
                                    <TextField
                                        className='col-span-2 md:col-span-1'
                                        name="password"
                                        label='Password*'
                                        variant="standard"
                                        type="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
        
                                        error={errors?.password != null}
                                        helperText={errors?.password}
                                    />
                                    <TextField
                                        className='col-span-2 md:col-span-1'
                                        name="password-confirm"
                                        label='Conferma password*'
                                        variant="standard"
                                        type="password"
                                        value={passwordConfirmation}
                                        onChange={(event) => setPasswordConfirmation(event.target.value)}
        
                                        error={errors?.passwordConfirmation != null}
                                        helperText={errors?.passwordConfirmation}
                                    />
                                    {failed && (
                                        <div className="mt-4">
                                            <Alert severity="error">
                                                Impossibile salvare la nuova password!<br/>
                                                { failureReason }
                                            </Alert>
                                        </div>
                                    )}
                                    <div className="flex gap-2 justify-end mt-4">
                                        <WashupOutlinedButton onClick={() => setIsEditing(false)} disabled={isLoading}>ANNULLA</WashupOutlinedButton>
                                        <WashupElevatedButton onClick={() => tryUpdate()}>SALVA</WashupElevatedButton>
                                    </div>
                                </div>
                            )}
                        </CardContent>
                    )
                }
            </Card>
        </>
    );
}
export default UserProfilePassword;