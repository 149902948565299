import moment from 'moment';

export class Page {

    constructor(data){
        this.id = data.id;
        this.slug = data.slug;
        this.type = data.type;
        this.priority = data.priority;
        this.title = data.title;
        this.body = data.body;
        this.cover_url = data.cover_url;
        this.showCover = data.show_cover;
        this.meta_title = data.meta_title;
        this.meta_description = data.meta_description;
        
        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');
    }
  }