import { Alert, Container, Typography } from '@mui/material';
import { useContext, useEffect} from 'react';
import WashupContext from '../../providers/WashupProvider';
import { WashupHeaderSlim } from '../_components/header';
import Loading from 'apps/_components/loading';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiResendVerify, apiVerify } from 'services/api';
import { useState } from 'react';
import { WashupElevatedButton } from 'apps/_components/button';

const EmailVerificationView = (props) => {
    const { user, cart, setShowLoginModal, logout } = useContext(WashupContext);
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate();
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isValid, setIsValid ] = useState(false);
    const [ isResent, setIsResent ] = useState(false);
    const [ isResentError, setIsResentError ] = useState(false);

    useEffect(() =>  {
        if(props.isVerifying){
            setIsLoading(true);
            setIsValid(false);
            apiVerify(searchParams.get('url')).then((result) => {
                setIsValid(result);
                setIsLoading(false);
            });
        }
        
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);
    
    const resendVerify = () => {
        setIsLoading(true);
        setIsResent(false);
        setIsResentError(false);
        apiResendVerify().then((result) => {
            if(result){
                setIsResent(true);
            }else{
                setIsResentError(true);
            }
            setIsLoading(false);
        });
    }

    if(isLoading){
        return <Loading />;
    }

    return (
        <> 
        <WashupHeaderSlim>
            <div className='flex gap-4 items-center'>
                <Typography variant='h4' className='p-4'>Verifica indirizzo email</Typography>
            </div>
        </WashupHeaderSlim>
        <Container maxWidth="md" className='mt-8'>
            { (isResent) && (
                <p>
                    <Alert className='mb-4' severity="success">Nuovo link inviato con successo, controlla la tua casella email.</Alert>
                </p>
            )}
            { (isResentError) && (
                <p>
                    <Alert severity="error">Impossibile inviare il nuovo link, assicurati di aver effettuato l'accesso e riprova.</Alert>
                </p>
            )}
            { ( props.isVerifying == null || !props.isVerifying ) ? (
                <div className='flex flex-col gap-4'>
                    <p>
                        <Alert severity="warning">Attenzione, indirizzo email non ancora verificato.</Alert>
                    </p>
                    <p>
                        Controlla la tua casella email (compresa la posta indesiderata) per le istruzioni.<br/>
                        Nel caso in cui l'email non fosse stata recapitata o il link fosse scaduto, procedi al reinvio del link o esci per usare il sito come ospite. 
                    </p>
                    <p className='flex gap-2 mt-4'>
                        <WashupElevatedButton variant="contained" onClick={() => resendVerify()}>INVIA NUOVAMENTE IL LINK</WashupElevatedButton>
                        <WashupElevatedButton variant="contained" onClick={() => logout()}>ESCI</WashupElevatedButton>
                        {cart != null ? (
                            <WashupElevatedButton variant="contained" onClick={() => navigate('/laundry/' + cart?.laundry.slug)}>CONTINUA LO SHOPPING</WashupElevatedButton>
                        ):(
                            <WashupElevatedButton variant="contained" onClick={() => navigate('/')}>TORNA ALLA HOMEPAGE</WashupElevatedButton>
                        )}
                    </p>
                </div>
            ):(
                <>
                    { (isValid) ? (
                        <div className='flex flex-col gap-4'>
                            <p>
                                <Alert severity="success">Indirizzo email verificato con successo!</Alert>
                            </p>
                            <p className='flex gap-2 mt-4'>
                                <WashupElevatedButton variant="contained" onClick={() => navigate('/')}>TORNA ALLA HOMEPAGE</WashupElevatedButton>
                            </p>
                        </div>
                    ):(
                        <>
                            { (user != null) ? (
                                <div className='flex flex-col gap-4'>
                                    <p>
                                        <Alert severity="error">Attenzione, questo link non è più valido.</Alert>
                                    </p>
                                    <p>
                                        Procedi al reinvio del link o esci per usare il sito come ospite. 
                                    </p>
                                    <p className='flex gap-2 mt-4'>
                                        <WashupElevatedButton variant="contained" onClick={() => resendVerify()}>INVIA NUOVAMENTE IL LINK</WashupElevatedButton>
                                        <WashupElevatedButton variant="contained" onClick={() => logout()}>ESCI</WashupElevatedButton>
                                    </p>
                                </div>
                            ):(
                                <div className='flex flex-col gap-4'>
                                    <p>
                                        <Alert severity="error">Attenzione, questo link non è più valido.</Alert>
                                    </p>
                                    <p>
                                        Effettua l'accesso per richiedere l'invio di un nuovo link.
                                    </p>
                                    <p className='flex gap-2 mt-4'>
                                        <WashupElevatedButton variant="contained" onClick={() => setShowLoginModal("login")}>ACCEDI</WashupElevatedButton>
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </>
            ) }
        </Container>
        </>
    );
  };
  
  export default EmailVerificationView;