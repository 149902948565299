import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WashupProvider } from "./providers/WashupProvider";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';


const THEME = createTheme({
  palette: {
    // @ts-ignore
    washup: {
      400: '#24bcef',
      500: '#037abb',
      600: '#0163a6',
    },
    primary: {
      main: '#037abb',
    },
    secondary: {
      main: '#AAAAAA',
    },
  },
  typography: {
   "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500,
   h1: {
    fontFamily: "Nunito",
   },
   h2: {
    fontFamily: "Nunito",
   },
   h3: {
    fontFamily: "Nunito",
   },
   h4: {
    fontFamily: "Nunito",
   },
   h5: {
    fontFamily: "Nunito",
   },
   h6: {
    fontFamily: "Nunito",
   },
   subtitle1: {
    fontFamily: "Nunito",
   },
   subtitle2: {
    fontFamily: "Nunito",
   },
   button: {
    fontFamily: "Nunito",
   },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <WashupProvider>
        <App />
      </WashupProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
