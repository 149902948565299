import { LaundryItem } from "./laundry-item";

export class Laundry {

    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
        this.icon_url = data.icon_url;
        this.meta_title = data.meta_title;
        this.meta_description = data.meta_description;

        this.items = data.items?.map((e) => new LaundryItem(e)) || [];
    }

    getLaundryItem = (item) => {
        return this.items.find((e) => e.item.id === item.id);
    }
  }
  
/*
  int id;
  late String name;
  late String slug;
  late String? description;
  late String icon;

  List<LaundryItem> laundryItems = [];

  Laundry.fromJson(dynamic json) {
    id = json["id"];
    name = json["name"];
    slug = json["slug"];
    description = json["description"];
    icon = json["icon"];

    if (json["items"] != null) {
      laundryItems = List<LaundryItem>.from(
          json["items"].map((e) => LaundryItem.fromJson(e)));
    }
  }

  LaundryItem? getItemIstance(Item item) {
    return laundryItems
        .where((element) => element.item.id == item.id)
        .firstOrNull;
  }
  */