// @ts-nocheck
import { Card, CardContent, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WashupHeaderSlim } from 'apps/_components/header';
import Loading from 'apps/_components/loading';
import { apiGetPage } from 'services/api';
import ReactHtmlParser from 'react-html-parser';
import React from 'react';
import PageForm from './_components/form';

import {Helmet} from "react-helmet";


const PageView = () => {
    const params = useParams();

    //const { user } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState();

    const getPage = async () => {
        setIsLoading(true);

        var res_page = await apiGetPage(params.id);
        setPage(res_page);

        setIsLoading(false);
    }

    useEffect(() =>  {
        getPage();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[params.id]);

    if(isLoading){
        return <Loading />;
    }
    if(page == null){
        return <></>
    }

    return (
        <> 
            <Helmet>
                <title>{page.meta_title}</title>
                <meta name="description" content={page.meta_description} />
            </Helmet>
            <WashupHeaderSlim>
                <div className='flex flex-col items-start'>
                    <Typography variant='h4' className='p-4'>{page.title}</Typography>
                </div>
            </WashupHeaderSlim>
            <Container maxWidth="md" className='mt-8'>
                { page.showCover && (
                    <img className='w-full mb-8' src={ page.cover_url } alt={page.title} />
                )}
                <Card>
                    <CardContent>
                        { ReactHtmlParser(page.body) }
                    </CardContent>
                </Card>
            </Container>
            <PageForm page={page} />
        </>
    );
  };
  
  export default PageView;