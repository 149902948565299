// @ts-nocheck
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import React, { useContext, useEffect, useState } from 'react';
import { IconButton, TextField, Alert, Link, Typography  } from '@mui/material';
import { Send, Cancel } from "@mui/icons-material";
import WashupContext from 'providers/WashupProvider';
import Loading from 'apps/_components/loading';


const OrderCoupon = (props) => {

    const { cart, setOrderCoupon } = useContext(WashupContext);

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState();

    useEffect(() =>  {
        setCouponCode(cart.coupon?.code);
    },[]);

    const setCoupon = async () => {
        setIsLoading(true);
        setError(false);
        var result = await setOrderCoupon(cart, couponCode);
        setError(!result);
        setIsLoading(false);
    }

    const unsetCoupon = async () => {
        setIsLoading(true);
        setError(false);
        setCouponCode(null);
        await setOrderCoupon(cart, null);
        setIsLoading(false);
    }


    if(isLoading) {
        return (
            <div className='p-2'>
                <Loading className='h-2' />
            </div>
        );
    }

    if(couponCode != null) {
        return (
            <>
                <div className='flex gap-4 items-end'>
                    <TextField
                        className='w-full'
                        label='Codice promozionale*'
                        name="first-name"
                        variant="standard"
                        value={couponCode}
                        onChange={(event) => setCouponCode(event.target.value)}
                        />
                        <IconButton
                            className='mr-2'
                            edge="start"
                            aria-label="menu"
                            disabled={couponCode.length < 3}
                            onClick={(event) => setCoupon()}
                        >
                            <Send color={couponCode.length < 3 ? 'disabled' : 'success'} />
                        </IconButton>
                        <IconButton
                            edge="start"
                            aria-label="menu"
                            onClick={(event) => unsetCoupon()}
                        >
                            <Cancel color="error" />
                        </IconButton>
                </div>
                { (error) && (
                    <Alert className='mt-2' severity="error">Codice promozionale non valido</Alert>
                )}
            </>
        );
    }

    return (
        <>
            {(true) ? (
                <Link href="#" underline="hover" onClick={() => setCouponCode("")}>
                    <Typography variant="body2">
                        Hai un codice promozionale?
                    </Typography>
                </Link>
            ):(

                <WashupElevatedButton onClick={() => []}>
                </WashupElevatedButton>
            )}
        </>
    );
}
export default OrderCoupon;