import { Container, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import WashupContext from '../../providers/WashupProvider';
import Loading from '../_components/loading';
import LaundryHeader from '../laundry/_components/header';
import CartStepOverview from './_components/cart-steps/overview';
import CartStepAddress from './_components/cart-steps/address';
import CartStepInfos from './_components/cart-steps/infos';
import CartStepPayment from './_components/cart-steps/payment';
import React from 'react';
import CartStepAddressGuest from './_components/cart-steps/address_guest';
import CartStepGuest from './_components/cart-steps/guest';

const CartView = () => {

    const { user, cart } = useContext(WashupContext);

    const [activeStep, setActiveStep] = useState(0);


    useEffect(() =>  {
        if(user == null && activeStep > 5){
            setActiveStep(5);
        }
        if(user != null && activeStep > 4){
            setActiveStep(4);
        }

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[ user, cart  ]);


    if(cart == null){
        return <Loading />;
    }

    return (
        <>
            <LaundryHeader laundry={cart.laundry} />
            <Container maxWidth="sm" className='mt-8'>
                    <Stepper activeStep={activeStep} orientation="vertical">

                        <Step key="overview">
                            <StepLabel>Riepilogo ordine</StepLabel>
                            <StepContent>
                            <CartStepOverview cart={cart} activeStep={activeStep} setActiveStep={setActiveStep} />
                            </StepContent>
                        </Step>
                        { user == null && (
                            <Step key="guest">
                                <StepLabel>Dati ospite</StepLabel>
                                <StepContent>
                                    <CartStepGuest type="pickup" selectedAddressId={cart.pickupAddressId} activeStep={activeStep} setActiveStep={setActiveStep} /> 
                                </StepContent>
                            </Step>
                        )}
                        <Step key="pickup_address">
                            <StepLabel>Indirizzo per il ritiro</StepLabel>
                            <StepContent>
                                { (user != null) ? (
                                    <CartStepAddress type="pickup" selectedAddressId={cart.pickupAddressId} activeStep={activeStep} setActiveStep={setActiveStep} /> 
                                ):(
                                    <CartStepAddressGuest type="pickup" selectedAddressId={cart.pickupAddressId} activeStep={activeStep} setActiveStep={setActiveStep} /> 
                                )}
                            </StepContent>
                        </Step>
                        <Step key="delivery_address">
                            <StepLabel>Indirizzo per la riconsegna</StepLabel>
                            <StepContent>
                                { (user != null) ? (
                                    <CartStepAddress type="delivery" selectedAddressId={cart.deliveryAddressId}  activeStep={activeStep} setActiveStep={setActiveStep} /> 
                                ):(
                                    <CartStepAddressGuest type="delivery" selectedAddressId={cart.deliveryAddressId}  activeStep={activeStep} setActiveStep={setActiveStep} /> 
                                )}
                            </StepContent>
                        </Step>
                        <Step key="additional_info">
                            <StepLabel>Note per il fattorino</StepLabel>
                            <StepContent>
                                <CartStepInfos cart={cart} activeStep={activeStep} setActiveStep={setActiveStep} /> 
                            </StepContent>
                        </Step>
                        <Step key="payment">
                            <StepLabel>Pagamento</StepLabel>
                            <StepContent>
                                <CartStepPayment cart={cart} activeStep={activeStep} setActiveStep={setActiveStep} /> 
                            </StepContent>
                        </Step>
                        
                    </Stepper>
            </Container>
        </>
    );
  };
  
  export default CartView;