import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import OrderOverview from "../overview";
import React, { useContext } from 'react';
import OrderCoupon from '../coupon';
import { Typography } from '@mui/material';
import WashupContext from 'providers/WashupProvider';
import { useNavigate } from 'react-router-dom';


const CartStepOverview = (props) => {

    const { user, setShowLoginModal } = useContext(WashupContext);

    return (
        <>
            <OrderOverview showCoupon />
            {false && (
                <div className='pt-4 pb-4'>
                    <OrderCoupon />
                </div>
            )}


            {user == null ? (
                <div className='flex flex-col justify-end mt-8 gap-4'>
                    <WashupElevatedButton onClick={() => setShowLoginModal("login")}>
                        <Typography variant="h6">ACCEDI o REGISTRATI</Typography>
                    </WashupElevatedButton>
        
                    <Typography variant='body2' className='text-center pt-4'>
                        E' possibile completare l'ordine senza effettuare l'accesso ma le tue preferenze non verranno salvate e non ti sarà possibile visualizzare i tuoi ordini precedenti.
                    </Typography>
                    <WashupOutlinedButton onClick={() => props.setActiveStep(props.activeStep + 1)}>
                        CONTINUA COME OSPITE
                    </WashupOutlinedButton>
                </div>
            ):(
                <div className='flex justify-end mt-8 gap-4'>
                    <WashupElevatedButton onClick={() => props.setActiveStep(props.activeStep + 1)}>Avanti</WashupElevatedButton>
                </div>
            )}
            
            
        </>
    );
}
export default CartStepOverview;