import { Page } from "_models/page";
import { ItemCategory } from "../_models/item-category";
import { Laundry } from "../_models/laundry";
import Order from "../_models/order";
import User from "../_models/user";
import http from "./http";
import axios from 'axios';
import { Coupon } from "_models/coupon";
import UserAddress from "_models/user-address";

/*
class ApiService {

    async getPlacesAutocomplete(input) {
        var result = await http.get('/laundries/search/map/autocomplete', {params: { input: input }});
        return result.data.predictions;
    }

}
export default ApiService;
*/

//********** */
// GOOGLE
//********** */

export const apiGetPlacesAutocomplete = async (input) => {
    var result = await http.get('/laundries/search/map/autocomplete',{params: { input: input }});
    return result.data.predictions;
}
export const apiGetGeocode = async (placeId) => {
    var result = await http.get('/laundries/search/map',{params: { place_id: placeId }});
    return result.data[0];
}

//********** */
// USER
//********** */


export const apiRegister = async (name, email, mobile, password, passwordConfirmation) => {
    try{
        var result = await http.post('/auth/register',{
            name: name,
            email: email,
            mobile: mobile,
            password: password,
            password_confirmation: passwordConfirmation,
        });
        return result.data.token;
    }catch(e){
        return;
    }
}

export const apiLogin = async (email, password) => {
    try{
        var result = await http.post('/auth/login',{
            email: email,
            password: password,
        });
        return result.data.token;
    }catch(e){
        return;
    }
}

export const apiGetUser = async () => {
    try{
        var result = await http.get('/user');
        return new User(result.data);
    }catch(e){
        return;
    }
}

export const apiVerify = async (url) => {
    try{
        await axios.get(url);
        return true;
    }catch(e){
        return false;
    }
}

export const apiResendVerify = async () => {
    try{
        await http.get('email/resend');
        return true;
    }catch(e){
        return false;
    }
}

export const apiSendPasswordRecovery = async (email) => {
    try{
        await http.post('/auth/password_recovery',{
            email: email,
        });
        return true;
    }catch(e){
        return false;
    }
}

export const apiResetPassword = async (token, email, password, passwordConfirm) => {
    try{
        await http.post('/auth/password_reset',{
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirm,
        });
        return true;
    }catch(e){
        return false;
    }
}

export const apiUpdateUserPassword = async (oldPassword, password, passwordConfirm) => {
    try{
        await http.put('/user/update_password' ,{
            old_password: oldPassword,
            password: password,
            password_confirmation: passwordConfirm,
        });
        return true;
    }catch(e){
        return e.response.data.message;
    }
}

export const apiUpdateUserInfo = async (name, email, mobile) => {
    try{
        await http.put('/user/update_info' ,{
            name: name, 
            email: email, 
            mobile: mobile, 
        });
        return true;
    }catch(e){
        return;
    }
}

export const apiUpdateUserAddress = async (address) => {
    try{
        var result = await http.put('/user_address/' + address.id ,{
            address: address.address, 
            postal_code: address.postalCode, 
            city: address.city, 
            province: address.province, 
        });
        return new UserAddress(result.data);
    }catch(e){
        return;
    }
}

export const apiDeleteUserAddress = async (address) => {
    try{
        await http.delete('/user_address/' + address.id);
        return true;
    }catch(e){
        return;
    }
}

//********** */
// ORDERS
//********** */

export const apiGetOrders = async () => {
    try{
        var result = await http.get('/orders');
        return result.data.map((e) => new Order(e));
    }catch(e){
        return;
    }
}

export const apiGetMyOrders = async () => {
    try{
        var result = await http.get('/orders/mine');
        return result.data.map((e) => new Order(e));
    }catch(e){
        return;
    }
}

export const apiGetCart = async () => {
    try{
        var result = await http.get('/orders/cart');
        return new Order(result.data);
    }catch(e){
        return;
    }
}

export const apiCreateCart = async (laundry) => {
    try{
        var result = await http.post('/orders/create_cart', {
            laundry_id: laundry.id,
        });
        return new Order(result.data);
    }catch(e){
        return;
    }
}

export const apiCreateGuestCart = async (guestCart) => {
    try{
        var result = await http.post('/orders/create_guest_cart', {
            guest_cart: guestCart,
        });
        return new Order(result.data);
    }catch(e){
        return;
    }
}

export const apiGetCoupon = async (couponCode) => {
    try{
        var result = await http.get('/coupons/' + couponCode);
        return new Coupon(result.data);
    }catch(e){
        return;
    }
}

export const apiGetOrder = async (orderId) => {
    try{
        var result = await http.get('/orders/' + orderId);
        return new Order(result.data);
    }catch(e){
        return;
    }
}

export const apiGetGuestOrder = async (orderId) => {
    try{
        var result = await http.get('/guest_orders/' + orderId);
        return new Order(result.data);
    }catch(e){
        return;
    }
}

export const apiAddOrderEntry = async (order, item, quantity, note) => {
    try{
        var result = await http.post('/orders/' + order.id + '/add_entry',{
            item_id: item.id,
            quantity: quantity, 
            note: note, 
        });
        return result.data;
    }catch(e){
        return;
    }
}

export const apiRemoveOrderEntry = async (order, item) => {
    try{
        var result = await http.post('/orders/' + order.id + '/remove_entry',{
            item_id: item.id,
        });
        return result.data;
    }catch(e){
        return;
    }
}

export const apiSetOrderAddress = async (order, type, address) => {
    try{
        var result = await http.post('/orders/' + order.id + '/set_address',{
            type: type,
            id: address.id, 
            address: address.address, 
            postal_code: address.postalCode, 
            city: address.city, 
            province: address.province, 
        });
        return result.data;
    }catch(e){
        return;
    }
}

export const apiSetOrderAdditionalInfos = async (order, infos) => {
    try{
        var result = await http.post('/orders/' + order.id + '/set_additional_infos',{
            additional_infos: infos
        });
        return result.data;
    }catch(e){
        return;
    }
}

export const apiSetOrderCoupon = async (order, couponCode) => {
    try{
        var result = await http.post('/orders/' + order.id + '/set_coupon_code',{
            coupon_code: couponCode
        });
        return result.data;
    }catch(e){
        return;
    }
}

export const apiGetPaymentLinks = async (orderId, paymentId) => {
    try{
        var result = await http.get('/orders/' + orderId + '/get_payment/' + paymentId);
        return result.data;
    }catch(e){
        return;
    }
}

export const apiGetOrderPaymentLinks = async (order) => {
    try{
        var result = await http.get('/orders/' + order.id + '/get_payment');
        return result.data;
    }catch(e){
        return;
    }
}

export const apiCompleteOrderPayment = async (order, token) => {
    try{
        var result = await http.get('/orders/' + order.id + '/complete_order_payment/' + token);
        return result.data;
    }catch(e){
        return;
    }
}

//********** */
// LANUDRIES
//********** */

export const apiGetLaundries = async (postalCode) => {
    //console.log("postalCode");
    //console.log(postalCode);
    var result = await http.get('/laundries',{params: { postal_code: postalCode }});
    return result.data.map((e) => new Laundry(e));
}

export const apiGetLaundry = async (slug) => {
    var result = await http.get('/laundries/' + slug);
    return new Laundry(result.data);
}

export const apiGetItemCategories = async () => {
    var result = await http.get('/item_categories');
    return result.data.map((e) => new ItemCategory(e));
}

//********** */
// PAGES
//********** */

export const apiGetPages = async () => {
    var result = await http.get('/pages');

    var pages = result.data;
    Object.keys(pages).forEach((key) => {
        pages[key] = pages[key].map((e) => new Page(e));
    });
    //console.log(pages);
    return pages;
}

export const apiGetPage = async (slug) => {
    var result = await http.get('/pages/' + slug);
    return new Page(result.data);
}

export const apiSendContactForm = async (page, name, location, email, mobile, isPolicyAccepted) => {
    try{
        await http.post('/pages/send_contact_form',{
            page: page,
            name: name,
            location: location,
            email: email,
            mobile: mobile,
            policy_accepted: isPolicyAccepted,
        });
        return true;
    }catch(e){
        return false;
    }
}

/*
  static Future<bool> removeEntryFromOrder(Item item) async {
    SharedPreferences preferences = await SharedPreferences.getInstance();
    String? token = preferences.getString(Washup.userTokenPrefsKey);

    Response res = await post(
        ApiService.getApiUri("orders/remove_entry", {
          'item_id': item.id.toString(),
        }),
        headers: {
          "Authorization": "Bearer $token",
        });

    try {
      json.decode(res.body);
      return true;
    } catch (e) {
      debugPrint(e.toString());
      return false;
    }
  }
*/