import React, { useContext } from 'react';
import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./apps/_layouts/main";
import HomeView from "./apps/home/home";
import LaundryView from './apps/laundry/laundry';
import CartView from './apps/order/cart';
import OrderView from './apps/order/order';
import OrderListView from './apps/order/list';
import PageView from 'pages/page';
import NewsView from 'pages/news';
import UserProfileView from 'apps/user/profile';
import WashupContext from 'providers/WashupProvider';
import EmailVerificationView from 'apps/user/email-verification';
import PasswordRecoveryView from 'apps/user/password-recovery';
import OrderPaymentRedirectView from 'apps/order/payment-redirect';




function App() {
  const { user, cart } = useContext(WashupContext);
  
  return (
    <BrowserRouter>
      <Routes>
        { (user != null && !user.isVerified) ? (
          <Route path="/" element={<Layout />}>
            <Route index element={<EmailVerificationView />} />
            <Route path="profile/email-verification" element={<EmailVerificationView isVerifying={true} />} />
            <Route path="*" element={<EmailVerificationView />} />
          </Route>  
        ):(
          <Route path="/" element={<Layout />}>
            <Route index element={<HomeView />} />
            <Route path="page">
              <Route path=":id" element={<PageView />} />
            </Route>
              <Route path="news" element={<NewsView />} />
            <Route path="laundry">
              <Route path=":slug" element={<LaundryView />} />
              <Route path=":slug/:itemCategorySlug" element={<LaundryView />} />
            </Route>
            <Route path="orders" element={<OrderListView />} />
            <Route path="order">
              <Route path=":id/payment/:paymentId" element={<OrderPaymentRedirectView />} />
              <Route path=":id" element={<OrderView />} />
            </Route>
            
            <Route path="cart" element={cart != null ? <CartView /> : <Navigate replace to="/" />} />

            <Route path="profile/email-verification" element={<EmailVerificationView isVerifying={true} />} />
            <Route path="profile/password-recovery" element={user == null ? <PasswordRecoveryView /> : <Navigate replace to="/" />} />
            <Route path="profile" element={user != null ? <UserProfileView /> : <Navigate replace to="/" />} />
            <Route path="*" element={<HomeView />} />
          </Route>  
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
