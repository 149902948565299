import { Card, CardContent, Divider, Radio, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import { useContext, useEffect, useState } from "react";
import WashupContext from "../../../../providers/WashupProvider";
import UserAddress from "../../../../_models/user-address";
import Loading from "../../../_components/loading";
import React from "react";


const CartStepGuest = (props) => {
    const { user, cart, setOrderUserData } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");


    useEffect(() =>  {
        setFirstName(cart.user?.first_name);
        setLastName(cart.user?.last_name);
        setEmail(cart.user?.email);
        setMobile(cart.user?.mobile);
    },[cart]);


    const setUser = async () => {
        setIsLoading(true);
        await setOrderUserData(cart, firstName, lastName, email, mobile);
        setIsLoading(false);
        props.setActiveStep(props.activeStep + 1);
    }

    const isComplete = () => {
        if(firstName == null || firstName === "") return false;
        if(lastName == null || lastName === "") return false;
        if(email == null || email === "") return false;
        if(mobile == null || mobile === "") return false;
        return true;
    }

    return (
        <>
            <Card className="mt-4">
                {(isLoading) ? (
                    <CardContent>
                        <div className="p-8">
                            <Loading />
                        </div>
                    </CardContent>
                ) : (
                    <CardContent>
                        <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                            <TextField
                                label='Nome*'
                                name="first-name"
                                variant="standard"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}
                            />
                            <TextField
                                label='Cognome*'
                                name="last-name"
                                variant="standard"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}
                            />
                        </div>
                        <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                            <TextField
                                label='Email*'
                                name="email"
                                variant="standard"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <TextField
                                label='Cellulare*'
                                name="mobile"
                                variant="standard"
                                value={mobile}
                                onChange={(event) => setMobile(event.target.value)}
                            />
                        </div>
                    </CardContent>
                )}
            </Card>
            <div className='flex justify-end mt-8 gap-4'>
                <WashupOutlinedButton onClick={() => props.setActiveStep(props.activeStep - 1)}>Indietro</WashupOutlinedButton>
                <WashupElevatedButton onClick={() => setUser()} disabled={!isComplete() || isLoading}>Avanti</WashupElevatedButton>
            </div>
        </>
    );
}
export default CartStepGuest;