// @ts-nocheck
import { Card, CardActionArea, CardContent, Container, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Loading from '../_components/loading';
import { apiGetMyOrders } from '../../services/api';
import { WashupHeaderSlim } from '../_components/header';
import { OrderStatusChip } from './_components/status-chip';
import { useNavigate } from 'react-router-dom';
import Empty from 'apps/_components/empty';
import React from 'react';

const OrderListView = () => {
    const navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState();
    const [filter, setFilter] = useState("current");

    const getOrders = async () => {
        setIsLoading(true);

        var res_orders = await apiGetMyOrders();
        setOrders(res_orders);

        setIsLoading(false);
    }

    useEffect(() =>  {
        getOrders();

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    if(isLoading){
        return <Loading />;
    }
    if(orders == null){
        return <></>
    }

    return (
        <> 
            <WashupHeaderSlim>
                <div className='flex gap-4 items-center'>
                    <Typography variant='h4' className='p-4'>I tuoi ordini</Typography>
                </div>
            </WashupHeaderSlim>
            <Container maxWidth="lg" className='mt-8'>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='hidden md:block'>
                    </div>
                    <div className='block md:hidden col-span-6'>
                        <div className="flex flex-col gap-2 bg-white m-auto p-8 rounded-3xl drop-shadow-md">
                            <Typography variant='h6' color="text.primary" className='mb-4'>Filtri</Typography>
                            <Divider />
                            <FormControl variant="standard" sx={{ m: 2, minWidth: 120 }}>
                                <InputLabel id="filter-select-label">Visualizza ordini</InputLabel>
                                <Select
                                    labelId="filter-select-label"
                                    id="filter-select"
                                    value={filter}
                                    onChange={(event) => setFilter(event.target.value)}
                                    label="Visualizza ordini"
                                >
                                    <MenuItem value={"current"}>In corso</MenuItem>
                                    <MenuItem value={"completed"}>Completati</MenuItem>
                                    <MenuItem value={"all"}>Tutti</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='col-span-6 md:col-span-3 flex flex-col items-stretch gap-4'>
                        {
                            (orders.length === 0) ? (
                                <Empty>Non ci sono ordini da visualizzare.</Empty>
                            ):(
                                orders.map((e) => {
                                    if(filter !== "all" && (
                                        (filter === "current" && (e.currentStatus.status.status === "completed" || e.currentStatus.status.status === "canceled")) || 
                                        (filter === "completed" && (e.currentStatus.status.status !== "completed" && e.currentStatus.status.status !== "canceled"))
                                    )){
                                        return null;
                                    }
                                    return  (
                                        <Card key={"order_" + e.id} variant='outlined'>
                                            <CardActionArea onClick={() => navigate("/order/" + e.id)}>
                                                <CardContent>
                                                    <div className='flex flex-col gap-4'>
                                                        <Typography variant='subtitle1'>{ e.laundry.name }</Typography>
                                                        <OrderStatusChip status={e.currentStatus.status} />
                                                        <div className="flex flex-row items-end">
                                                            <div className='w-full'>
                                                                <Typography variant='body1'>{ e.completedAt }</Typography>
                                                            </div>
                                                            <div className='flex gap-2'>
                                                                <Typography variant='h6' className='text-center'>€</Typography>
                                                                <Typography variant='h6' className='text-center'>{ Number(e.itemsFinalPrice).toFixed(2) }</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    )
                                })
                            )
                        }
                    </div>
                    <div className='hidden md:block col-span-2'>
                        <div className="flex flex-col gap-2 bg-white m-auto p-8 rounded-3xl drop-shadow-md">
                            <Typography variant='h6' color="text.primary" className='mb-4'>Filtri</Typography>
                            <Divider />
                            <FormControl variant="standard" sx={{ m: 2, minWidth: 120 }}>
                                <InputLabel id="filter-select-label">Visualizza ordini</InputLabel>
                                <Select
                                    labelId="filter-select-label"
                                    id="filter-select"
                                    value={filter}
                                    onChange={(event) => setFilter(event.target.value)}
                                    label="Visualizza ordini"
                                >
                                    <MenuItem value={"current"}>In corso</MenuItem>
                                    <MenuItem value={"completed"}>Completati</MenuItem>
                                    <MenuItem value={"all"}>Tutti</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                </div>
            </Container>
        </>
    );
  };
  
  export default OrderListView;