import { Card, CardContent, CircularProgress, Typography, Switch, FormControlLabel, Link } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import { apiCreateCart, apiGetOrderPaymentLinks } from "services/api";
import { useContext, useState } from "react";
import React from "react";
import WashupContext from "providers/WashupProvider";


const CartStepPayment = (props) => {
    const { user, createGuestCart } = useContext(WashupContext);
    const [isLoading, setIsLoading] = useState(false);

    const [istermsAccepted, setIstermsAccepted] = useState(false);
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);


    const canGoToPayment = () => {
        return istermsAccepted && (user != null || isPrivacyPolicyAccepted);
    }

    const goToPayment = async () => {
        setIsLoading(true);

        var payment;
        //console.log(props.cart);

        if(user == null){
            var guestCart = await createGuestCart();
            payment = await apiGetOrderPaymentLinks(guestCart);
        }else{
            payment = await apiGetOrderPaymentLinks(props.cart);
        }

        var link = payment.links.find((e) => e.rel === "payer-action");
        window.location.href = link.href;
        setIsLoading(false);
        
    }

    return (
        <>
            <Card>
                <CardContent className='flex flex-col gap-2'>
                    <Typography variant="body1">Alcune informazioni sul pagamento, termini, rimborsi etc.</Typography>
                    <table border={0} cellPadding="10" cellSpacing="0" align="center">
                        <tr><td align="center"></td></tr>
                        <tr><td align="center">
                            <a href="https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2" title="PayPal" onClick={() => window.open('https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700')}>
                                <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png" alt="PayPal Logo" />
                            </a>
                        </td></tr>
                    </table>
                </CardContent>
            </Card>
            <div className="pt-4 md:px-4">
                <FormControlLabel control={<Switch value={istermsAccepted} onChange={(event) => setIstermsAccepted(event.target.checked)} />} label={<>Accetto i <Link href="http://www.washupitalia.it/page/termini-e-condizioni" underline="hover" target="_blank">Termini e Condizioni</Link></>} />
                { user == null && (
                    <FormControlLabel control={<Switch value={isPrivacyPolicyAccepted} onChange={(event) => setIsPrivacyPolicyAccepted(event.target.checked)} />} label={<>Accetto la  <Link href="https://www.washupitalia.it/privacy-policy" underline="hover" target="_blank">Privacy Policy</Link></>} />
                ) }
            </div>
            <div className='flex flex-col md:flex-row justify-end items-start mt-8 gap-4'>
                <WashupOutlinedButton onClick={() => props.setActiveStep(props.activeStep - 1)}>Indietro</WashupOutlinedButton>
                <WashupElevatedButton onClick={() => goToPayment()} style={{ width: "300px", height: "70px" }} disabled={!canGoToPayment()} >
                    {
                        (isLoading) ? (
                            <CircularProgress style={{color: 'white'}} />
                        ) : (
                            <div>
                                Procedi al pagamento
                                <Typography variant="h5">€ {Number(props.cart.getTotalPrice()).toFixed(2)}</Typography>
                            </div>
                        )
                    }
                </WashupElevatedButton>
            </div>
        </>
    );
}
export default CartStepPayment;