import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import React from "react";

const PageCard = (props) => {
    const navigate = useNavigate();

    return (
        <Card>
            <CardActionArea onClick={() => navigate("/page/" + props.page.slug)}>
                <CardMedia
                    component="img"
                    sx={{ height: 140 }}
                    image={ props.page.cover_url }
                    alt={props.page.title}
                    
                    className=" bg-gradient-to-br from-washup-400 via-washup-500 to-washup-600"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Inserita il { props.page.createdAt }
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.page.title}
                    </Typography>
                    <Divider />
                    <Typography variant="body2" color="text.secondary">
                        { ReactHtmlParser(props.page.excerpt) }
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={() => navigate("/page/" + props.page.slug)}>
                    Leggi tutto
                </Button>
            </CardActions>
        </Card>
    );
}
export default PageCard;