// @ts-nocheck
import React, { useContext, useState, useEffect } from "react";
import { Box, Divider, Modal, TextField, Typography } from "@mui/material";
import WashupContext from "providers/WashupProvider";
import { WashupElevatedButton, WashupOutlinedButton } from "apps/_components/button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Loading from "apps/_components/loading";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const OrderEntryEditModal = (props) => {
    const { cart, addOrderEntry } = useContext(WashupContext);

    const [isLoading, setIsLoading] = useState(false);
    const [hasEntry, setHasEntry] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [note, setNote] = useState("");

    const item = props.item;

    useEffect(() => {
        if(cart?.entries != null) {
            cart.entries.forEach(entry => {
                if(entry.item.id === props.item.id){
                    setQuantity(entry.quantity);
                    setNote(entry.note ?? "");
                    setHasEntry(true);
                }
            });
        }
    },[props.item]);


    const finalizeOrderEntry = async (discardCart = false) => {
        setIsLoading(true);
        
        await addOrderEntry(props.laundry, cart, item, quantity, note);
        props.onFinalize(true);
    }

    if(props.item == null){
        return <></>;
    }

    return (
        <Modal
            open={item != null}
            onClose={() => props.onFinalize(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    { item != null && (
                        <div className="w-80 md:w-128 p-4 md:p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md relative">
                            <div className="absolute inset-0 flex items-center justify-center" style={{ visibility: (!isLoading) ? 'hidden' : 'visible'}}>
                                <Loading />
                            </div>
                            <div style={{ visibility: (isLoading) ? 'hidden' : 'visible'}}>
                                <Typography variant="h6">Aggiungi all'ordine</Typography>
                                <Divider className="mt-4 ,b-4" />
                                <div className="p-4 pt-8 flex flex-col gap-4 items-center">
                                    <img className='w-20 md:w-24' src={item.icon_url} alt={item.name} />
                                    <Typography variant="h5" className="text-center">{item.name}</Typography>
                                    <Typography variant="subtitle1" className="text-center">{item.description}</Typography>
                                    <Divider />
                                    <div className="flex gap-8 justify-center -8">
                                        <WashupOutlinedButton onClick={() => setQuantity(quantity - 1)} disabled={quantity === 1} ><RemoveCircleOutlineIcon /></WashupOutlinedButton>
                                        <Typography variant="h4" className="text-center">{quantity}</Typography>
                                        <WashupOutlinedButton onClick={() => setQuantity(quantity + 1)}><AddCircleOutlineIcon /></WashupOutlinedButton>
                                    </div>
                                    <Divider />
                                    <TextField
                                        className='w-full'
                                        label='Note per Lavanderia'
                                        variant="standard"
                                        multiline
                                        rows={2}
                                        value={note}
                                        onChange={(event) => setNote(event.target.value)}
                                    />
                                    <Divider />
                                    <WashupElevatedButton className="w-full" onClick={() => finalizeOrderEntry()}>
                                        { hasEntry ? (
                                            <>Aggiorna l'ordine</>
                                        ):(
                                            <>Aggiungi all'ordine</>
                                        )}
                                        
                                    </WashupElevatedButton>
                                </div>
                            </div>
                        </div>
                    )}
                </Box>
        </Modal>
    )

}
export default OrderEntryEditModal;