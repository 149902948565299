import { Outlet } from "react-router-dom";
import WashupAppBar from "../_components/app-bar";
import WashupFooter from "../_components/footer";
import React, { useContext } from "react";
import WashupContext from "../../providers/WashupProvider";
import { Drawer, Modal } from "@mui/material";
import WashupDrawer from "../_components/drawer";
import LoginModal from "../user/_components/login-modal";
import RegisterModal from "apps/user/_components/register-modal";
import WashupMenuDrawer from "apps/_components/menu-drawer";

const MainLayout = () => {
const { showDrawer, setShowDrawer, showMenuDrawer, setShowMenuDrawer, showLoginModal, setShowLoginModal } = useContext(WashupContext);

  return (
    <>
        <WashupAppBar />
        <div className="w-full h-full flex flex-col">
          <div className="mt-20 mb-16 flex-grow">
              <Outlet />
          </div>
          <WashupFooter />
        </div>
      
        <Drawer
            anchor="right"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            onClick={() => setShowDrawer(false)}
            >
            <WashupDrawer />
        </Drawer>
        <Drawer
            anchor="right"
            open={showMenuDrawer}
            onClose={() => setShowMenuDrawer(false)}
            onClick={() => setShowMenuDrawer(false)}
            >
            <WashupMenuDrawer />
        </Drawer>

        <Modal
            open={showLoginModal != null}
            onClose={() => setShowLoginModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              <>
                { (showLoginModal === "login") && <LoginModal />}
                { (showLoginModal === "register") && <RegisterModal />}
              </>
            
        </Modal>
    </>
  )
};

export default MainLayout;