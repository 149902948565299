import UserAddress from "./user-address";
import moment from 'moment';

class User {
    constructor(data){
        this.id = data.id;
        this.name = data.name;

        this.fistName = this.name.split(' ')[0];

        this.email = data.email;
        this.mobile = data.mobile;

        this.isVerified = data.email_verified_at != null;

        //this.cart = new Order(data.cart);

        this.address = data.address.map((e) => new UserAddress(e));

        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');
    }
}
export default User;