// @ts-nocheck
import { Accordion, AccordionSummary, AccordionDetails, Container, Divider, Typography, Card, Link, Modal, Chip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Loading from '../_components/loading';
import { useParams, useSearchParams } from 'react-router-dom';
import { apiGetGuestOrder, apiGetOrder } from '../../services/api';
import LaundryHeader from '../laundry/_components/header';
import OrderOverview from './_components/overview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrderStatusChip } from './_components/status-chip';
import OrderPaymentModal from './_components/payment-modal';
import WashupContext from '../../providers/WashupProvider';

const OrderView = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();

    const { user, deleteLocalCart } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [order, setOrder] = useState();

    const getOrder = async () => {
        setIsLoading(true);

        var res_order = await apiGetOrder(params.id);
        if(res_order == null){
            res_order = await apiGetGuestOrder(params.id);
        }

        setOrder(res_order);
        setIsLoading(false);
    }

    useEffect(() =>  {
        getOrder();
        if(searchParams.get("payment") != null){
            if(searchParams.get('payment') === "success"){
                deleteLocalCart();
            }
            setShowPaymentModal(true);
        }

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[ params.id ]);

    if(isLoading){
        return <Loading />;
    }
    if(order == null){
        return <Loading />;
    }

    return (
        <> 
            <LaundryHeader laundry={order.laundry} />
            <Container maxWidth="lg" className='mt-8'>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='hidden md:flex flex-col gap-2 mt-4 sticky justify-start'>
                        <Link underline="hover" color="inherit" href="#order">Ordine</Link>
                        <Link underline="hover" color="inherit" href="#status">Stato</Link>
                        <Link underline="hover" color="inherit" href="#payments">Pagamenti</Link>
                        <Link underline="hover" color="inherit" href="#address">Indirizzi</Link>
                    </div>
                    <div className='col-span-6 md:col-span-3 bg-white p-8 rounded-3xl drop-shadow-md'>
                        <div id="order" className='mb-8'>
                            <div className='flex w-full gap-4 items-end'>
                                <Typography variant='h5' className='flex-grow'>Ordine #{ order.id }</Typography>
                                <Typography variant='body2'>del { order.completedAt }</Typography>
                            </div>
                            <OrderOverview order={order} />
                        </div>
                        <div id="status" className='mb-8'>
                            <Typography variant='h5' className='flex-grow'>Stato</Typography>
                            <div className='py-2'>
                                <OrderStatusChip status={order.currentStatus.status} />
                            </div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="all-content"
                                    id="all-header"
                                >
                                    <Typography variant='subtitle1'>Cronologia completa</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='flex flex-col gap-4'>
                                        {
                                            order.statusHistory?.map((e) => {
                                                return (
                                                    <div key={"status_" + e.id} className='flex flex-col md:flex-row gap-2 items-center'>
                                                        <div className='flex-grow'>
                                                            <small>{ e.createdAt }</small>
                                                        </div>
                                                        <OrderStatusChip size="s" status={e.status} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div id="payments" className='mb-8'>
                            <Typography variant='h5'>Pagamenti</Typography>
                            <Card className='flex flex-col gap-4 p-4 items-stretch'>
                                {
                                    ( order.payments.length > 0 ) ? (
                                        order.payments.map((e) => (
                                            <div key={"payment_" + e.id} className='flex gap-4 w-full'>
                                                <div className='flex-grow'>
                                                    <Typography variant='subtitle1'> { e.createdAt }</Typography>
                                                    <Typography variant='body2'>{ e.refId }</Typography>
                                                </div>
                                                {
                                                    (e.isPaid) ? (
                                                        <Chip color="success" label="Completato" variant='outlined' /> 
                                                    ):(
                                                        <Chip color="error" label="Non completato" variant='outlined' /> 
                                                    )
                                                }
                                            </div>
                                        ))
                                    ):(
                                        <Typography variant="body2">Nessun pagamento presente per questo ordine</Typography>
                                    )
                                }
                            </Card>
                        </div>
                        <div id="address" className='mb-8'>
                            <Typography variant='h5'>Indirizzi</Typography>
                            <Card className='flex gap-4 p-4 items-stretch'>
                                <div className='flex-grow flex flex-col gap-2'>
                                    <Typography variant='subtitle1'>Ritiro</Typography>
                                    <Divider />
                                    <Typography variant='body2'>
                                        { order.pickupAddress }
                                    </Typography>
                                </div>
                                <Divider orientation='vertical' />
                                <div className='flex-grow flex flex-col gap-2'>
                                    <Typography variant='subtitle1'>Riconsegna</Typography>
                                    <Divider />
                                    <Typography variant='body2'>
                                        { order.deliveryAddress }
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='col-span-6 md:col-span-2'>
                        <div className="bg-white m-auto p-8 rounded-3xl drop-shadow-md">
                            <Typography variant='h6' color="text.primary" className='mb-4'>Azioni</Typography>
                            <Divider />
                        </div>
                    </div>

                </div>
            </Container>

            <Modal
                open={showPaymentModal}
                onClose={() => setShowPaymentModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <OrderPaymentModal order={order} onClose={() => setShowPaymentModal(false)} />
            </Modal>
        </>
    );
  };
  
  export default OrderView;