// @ts-nocheck
import { Autocomplete, Card, CardContent, CardActionArea, Container, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { WashupHeader } from "../_components/header";
import SearchIcon from '@mui/icons-material/Search';
import { WashupElevatedButton } from 'apps/_components/button';
import { useContext, useEffect, useState } from 'react';
import { apiGetPlacesAutocomplete, apiGetGeocode, apiGetLaundries, apiGetMyOrders } from '../../services/api';
import Loading from '../_components/loading';
import { useNavigate } from 'react-router-dom';
import Empty from 'apps/_components/empty';
import PageCard from 'pages/_components/card';
import WashupContext from 'providers/WashupProvider';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import {Helmet} from "react-helmet";


const SearchTextField = styled(TextField)({
    '& input:valid + fieldset': {
      borderColor: '#E0E3E7',
      borderWidth: 1,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 4,
      padding: '4px !important', // override inline-style
    },
  });

const HomeView = () => {
    const { pages } = useContext(WashupContext);
    const navigate = useNavigate();

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [input, setInput] = useState("");
    const [predictions, setPredictions] = useState([]);
    const [address, setAddress] = useState();
    const [laundries, setLaundries] = useState();
    const [orders, setOrders] = useState();
    const [isPostalCodeMissing, setIsPostalCodeMissing] = useState(false);
    const [timer, setTimer] = useState();


    const getPredictions = async (search_input) => {
        setInput(search_input);
        setIsLoading(true);

        if(timer != null){
            clearTimeout(timer);
        }

        var new_timer = setTimeout(async () => {
            if(search_input !== undefined && search_input.length > 2){
                var res_predictions = await apiGetPlacesAutocomplete(search_input);
                setPredictions(res_predictions);
                setIsLoading(false);
            }
        }, 1000);
        setTimer(new_timer);

    }
    const getLaundries = async () => {
        setIsSearchLoading(true);
        var res_geocode = await apiGetGeocode(address);
        if(res_geocode.postal_code != null){
            var res_laundries = await apiGetLaundries(res_geocode.postal_code);
            setIsPostalCodeMissing(false);
            setLaundries(res_laundries);
        }else{
            setIsPostalCodeMissing(true);
            setLaundries([]);
        }

        setIsSearchLoading(false);
    }
    const getOrders = async () => {
        setIsLoading(true);

        var res_orders = await apiGetMyOrders();
        setOrders(res_orders);

        setIsLoading(false);
    }


    useEffect(() =>  {
        getOrders()
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    var homepage = (pages && pages.home && pages.home[0]) ? pages.home[0] : null;

    console.log("orders");
    console.log(orders);

    return (
        <>
            <Helmet>
                <title>{ homepage != null ? homepage.meta_title : null }</title>
                <meta name="description" content={ homepage != null ? homepage.meta_description : null } />
                <meta name="description" content="" />
            </Helmet>
            
            <WashupHeader bannerStyle={{ backgroundImage:   'url("' + ( homepage != null ? homepage.cover_url : "" ) + '")' }} banner={(
                    <Container maxWidth="md" className='h-44 md:h-52'>
                    {/*
                        <Typography variant="h3" color="#FFFFFF" className='pb-10 pt-10'>Your Smart<br/>Laundry</Typography>
                    */}
                    </Container>
                )}>
                <div className='p-4'>
                    <Typography variant="h1" className='text-center' style={{ fontSize: "2rem" }}>
                        WashUp: la tua lavanderia a domicilio
                    </Typography>
                    <Typography variant="h2" className='text-center' style={{ fontSize: "1.25rem" }}>
                        Cerca la lavanderia più vicina a te!
                    </Typography>
                    <div className='flex flex-col md:flex-row mt-8 gap-8'>
                        <Autocomplete
                            disablePortal
                            filterOptions={(x, s) => x}
                            id="geo-search"
                            options={predictions}
                            className='flex-1'
                            noOptionsText="Nessuna opzione trovata"
                            loading={isLoading}
                            loadingText="Ricerca in corso..."
                            getOptionLabel={(option) => option.description}
                            onChange={(event, value) => {
                                setLaundries();
                                setAddress(value?.place_id);
                                setInput(value?.description);
                            }}
                            renderInput={(params) => <TextField {...params} label="Inserisci il tuo indirizzo" variant="standard" value={input} onChange={(event) => getPredictions(event.target.value)}  InputLabelProps={{style: { fontWeight: 'bold', fontSize: "120%" },}} />}
                        />
                        <WashupElevatedButton onClick={() => getLaundries()} variant="contained" startIcon={<SearchIcon />} disabled={address == null}>Cerca</WashupElevatedButton>
                    </div>
                </div>
            </WashupHeader>
            {
                (isSearchLoading) ? (
                    <Container maxWidth="md" className='p-16'>
                        <Loading />
                    </Container>
                ) : (
                    <Container maxWidth="md" className='flex flex-col gap-2'>
                        {
                            (laundries !== undefined) ? (
                                <>
                                    <Typography variant='h5' className='text-center p-8'>Ecco le lavanderie nella zona!</Typography>

                                    <div className='flex flex-col gap-4'>
                                        {
                                            (laundries.length === 0) ? (
                                                <Card>
                                                    <CardContent>
                                                        { isPostalCodeMissing ? (
                                                            <Empty>Nessuna CAP univoco associato a questo indirizzo, prova con una ricerca più specifica.</Empty>
                                                        ):(
                                                            <Empty>Nessuna lavanderia trovata nella zona di "{input}".</Empty>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            ):(
                                                laundries.map(laundry => (
                                                    <Card key={"laundry_" + laundry.id} variant='outlined'>
                                                        <CardActionArea  onClick={() => navigate("/laundry/" + laundry.slug)}>
                                                            <CardContent>
                                                                <div className={'flex gap-4 ' + (laundry.description != null && laundry.description !== "" ? 'items-start' : 'items-center')}>
                                                                    <img className='w-14' src={laundry.icon_url} alt={ laundry.name } />
                                                                    <div>
                                                                        <Typography variant='h6'>{ laundry.name }</Typography>
                                                                        <Typography variant='body2' className="px-4">{ ReactHtmlParser(laundry.description) }</Typography>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                ))

                                            )
                                        }
                                    </div>
                                
                                </>
                            ) : (
                                <></>
                            )
                        }
                    </Container>
                )
            }
            { orders != null && orders.length > 0 && (
                <Container maxWidth="md" className='flex flex-col gap-2'>
                    <Typography variant='h5' className='text-center p-8'>Torna all’ultima lavanderia utilizzata</Typography>

                    <div className='flex flex-col gap-4'>
                        <Card key={"laundry_" + orders[0].laundry.id} variant='outlined'>
                            <CardActionArea  onClick={() => navigate("/laundry/" + orders[0].laundry.slug)}>
                                <CardContent>
                                    <div className='flex gap-4 items-center'>
                                        <img className='w-14' src={orders[0].laundry.icon_url} alt={ orders[0].laundry.name } />
                                        <Typography variant='h6'>{ orders[0].laundry.name }</Typography>
                                    </div>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                </Container>
            )}
            <div className="mt-16">
                <Typography variant='h5' className='text-center p-8'>Tieniti aggiornato</Typography>
                {
                    (pages?.news == null) ? (
                        <Container maxWidth="md">
                            <Loading />
                        </Container>
                    ):(
                        <Container maxWidth="md">
                            <div className='flex flex-col gap-8 items-center'>
                                <div className='flex flex-col md:flex-row gap-4 w-full'>
                                    <div className="w-full md:w-1/2">
                                        <PageCard page={pages.news[0]} />
                                    </div>
                                    <div className='w-full md:w-1/2'>
                                        <PageCard page={pages.news[1]} />
                                    </div>
                                </div>
                                <WashupElevatedButton onClick={() => navigate('/news')}>Leggi tutte le news</WashupElevatedButton>
                            </div>
                        </Container>
                    )
                }
            </div>
        </>
    );
  };
  
  export default HomeView;