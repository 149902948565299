import { Item } from "./item";

class OrderEntry {
    constructor(data){
        this.quantity = data.quantity;
        this.finalPrice = data.final_price;
        this.note = data.note;

        this.item = data.item != null ? new Item(data.item) : null;
    }

    getTotalPrice = (laundry) => {
        if(this.finalPrice != null){
            return this.finalPrice;
        }
        var laundryItem = laundry.getLaundryItem(this.item);
        return laundryItem.finalPrice * this.quantity;
    }
}
export default OrderEntry;