// @ts-nocheck
import { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from "apps/_components/button";
import Loading from "apps/_components/loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiCompleteOrderPayment } from "services/api";
import { SentimentSatisfied, SentimentDissatisfied } from "@mui/icons-material";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const OrderPaymentModal = (props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ payment, setPayment ] = useState(true);

    
    const completePayment = async () => {
        setIsLoading(true);
        var res_payment = await apiCompleteOrderPayment(props.order, searchParams.get('token'));
        setPayment(res_payment);
        setIsLoading(false);
    }

    const goToPayment = async () => {
        setIsLoading(true);
        var link = payment.links.find((e) => e.rel === "payer-action");
        window.location.href = link.href;
        setIsLoading(false);
        
    }

    useEffect(() =>  {
        completePayment()
    },[]);

    return (
        <Box sx={style}>
            <div className="w-96 p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md">
                <Typography variant="h6">Esito del pagamento</Typography>
                <Divider className="mt-4 ,b-4" />
                {
                    (isLoading) ? (
                        <div className="h-48">
                            <Loading />
                        </div>
                    ) : (
                        (searchParams.get('payment') === "success") ? (
                            <div className="p-4 flex flex-col gap-4 items-center">
                                <SentimentSatisfied sx={{ fontSize: 200 }} color="success" />
                                <Typography variant="h5">Pagamento effettuato con successo!</Typography>
                                <Typography variant="body1">Il tuo ordine verrà ora gestito dai nostri operatori.</Typography>
                                <Divider />
                                {/*
                                <WashupOutlinedButton href={"/order/" + (props.order.guest_id ?? props.order.id)}>VAI ALL'ORDINE</WashupOutlinedButton>
                                */}
                                <WashupOutlinedButton href={ window.location.href.split('?')[0] }>VAI ALL'ORDINE</WashupOutlinedButton>
                            </div>
                        ):(
                            <div className="p-4 flex flex-col gap-4 items-center">
                                <SentimentDissatisfied sx={{ fontSize: 200 }} color="error" />
                                <Typography variant="h5">Pagamento non effettuato!</Typography>
                                <Typography variant="body1">Ritenta con un metodo di pagamento diverso o contattaci in caso di problemi con il pagamento.</Typography>
                                <Divider />
                                <WashupElevatedButton onClick={() => goToPayment()}>RITENTA IL PAGAMENTO</WashupElevatedButton>
                                <WashupOutlinedButton onClick={() => navigate("/cart")}>TORNA AL CARRELLO</WashupOutlinedButton>
                            </div>
                        )
                    )
                }
            </div>
        </Box>
    )

}
export default OrderPaymentModal;