import { Divider, Typography } from "@mui/material";
import { WashupOutlinedButton, WashupElevatedButton } from "./button";
import { useContext } from "react";
import WashupContext from "../../providers/WashupProvider";
import { useNavigate } from "react-router-dom";
import React from "react";

const WashupDrawer = () => {
    const { user, logout, setShowLoginModal } = useContext(WashupContext);
    const navigate = useNavigate();

    return (
        <div className="w-80">
            <div className="p-16 bg-gradient-to-br from-washup-400 via-washup-500 to-washup-600 text-center">
                <img src="/images/washup_web_light.png" className='h-24' alt="washup-logo" />
            </div>
            {
                (user !== undefined) ? (
                    <div className="flex flex-col gap-4 p-8">
                        <div>
                            <Typography variant="h6" className="text-center">Ciao {user.name}!</Typography>
                            <Typography variant="subtitle2" className="text-center">{user.email}</Typography>
                        </div>
                        <Divider className="mt-8 mb-8" />
                        <WashupOutlinedButton onClick={() => navigate("/profile")}>Profilo</WashupOutlinedButton>
                        <WashupOutlinedButton onClick={() => navigate("/orders")}>Ordini</WashupOutlinedButton>
                        <Divider className="mt-8 mb-8" />
                        <WashupElevatedButton onClick={() => logout()}>Esci</WashupElevatedButton>
                    </div>
                ) : (
                    <div className="flex flex-col gap-4 p-8">
                        <div>
                            <Typography variant="h6" className="text-center">Benvenuto su WashUp!</Typography>
                            <Typography variant="subtitle2" className="text-center">Accedi per approfittare dei servizi WashUp e visualizzare i tuoi dati.</Typography>
                        </div>
                        <Divider className="mt-8 mb-8" />
                        <WashupElevatedButton onClick={() => setShowLoginModal("login")}>Accedi</WashupElevatedButton>
                        <Divider className="mt-8 mb-8" />
                        <Typography variant="subtitle2" className="text-center">Non hai ancora un account?</Typography>
                        <WashupElevatedButton onClick={() => setShowLoginModal("register")}>Registrati</WashupElevatedButton>
                    </div>
                )
            }
        </div>
    )
}

export default WashupDrawer;