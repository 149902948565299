// @ts-nocheck
import axios from 'axios';

const http = axios.create({
    baseURL: "https://gestionale.washupitalia.it/api/",
    //baseURL: "http://127.0.0.1:8000/api/",
    //withCredentials: true,
    //headers: {
    //    'Content-Type': 'application/json',
    //}
});


http.interceptors.request.use(
  async config => {
    var token = await localStorage.getItem("washupUserToken");
    config.headers = { 
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

/*
// Response interceptor for API calls
http.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});
*/


export default http;