import { Box, Card, CardContent, Divider, IconButton, Modal, Radio, TextField, Typography } from "@mui/material";
import UserAddress from "_models/user-address";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import Loading from "apps/_components/loading";
import WashupContext from "providers/WashupProvider";
import { useContext, useState } from "react";
import React from "react";
import { Delete, Edit } from '@mui/icons-material';
import { apiDeleteUserAddress, apiUpdateUserAddress } from "services/api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const UserProfileAddress = (props) => {
    const { getUser } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [userAddress, setUserAddress] = useState(props.address);
    const [address, setAddress] = useState( "");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");


    const updateAddress = async () => {
        setIsLoading(true);
        var data = new UserAddress({
            id: userAddress.id,
            address: address,
            postal_code: postalCode,
            city: city,
            province: province,
        });
        var newAddress = await apiUpdateUserAddress(data);
        setUserAddress(newAddress);

        setIsEditing(false);
        setIsLoading(false);
    }
    const deleteAddress = async () => {
        setShowDeleteModal(false);
        setIsLoading(true);

        await apiDeleteUserAddress(userAddress);

        await getUser();

        setUserAddress(null);
        setIsLoading(false);
    }

    const startEdit = () => {
        setAddress(userAddress.address);
        setPostalCode(userAddress.postalCode);
        setCity(userAddress.city);
        setProvince(userAddress.province);
        
        setIsEditing(true);
    }

    const isComplete = () => {
        if(address == null || address === "") return false;
        if(postalCode == null || postalCode.length !== 5) return false;
        if(city == null || city === "") return false;
        if(province == null || province === "") return false;
        return true;
    }


    if(userAddress == null){
        return <></>;
    }

    return (
        <>
            <Card key={"address_" + userAddress.id} className="mt-4">
                {
                    (isLoading) ? (
                        <CardContent>
                            <div className="p-8">
                                <Loading />
                            </div>
                        </CardContent>
                    ) : (
                        <CardContent>
                            {!isEditing ? (
                                <div className="flex gap-2">
                                    <div className='flex flex-col gap-2 flex-grow'>
                                        <Typography variant='subtitle1'>{userAddress.address}</Typography>
                                        <Typography variant='body2'>{userAddress.postalCode} {userAddress.city}</Typography>
                                        <Typography variant='body2'>{userAddress.province}</Typography>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <IconButton aria-label="edit" onClick={() => startEdit()} >
                                            <Edit />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="error" onClick={() => setShowDeleteModal(true)}>
                                            <Delete />
                                        </IconButton>
                                    </div>
                                </div>
                            ):(
                                <>
                                    <TextField
                                        className='w-full'
                                        name="address"
                                        label='Indirizzo*'
                                        variant="standard"
                                        value={address}
                                        onChange={(event) => setAddress(event.target.value)}
                                    />
                                    <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                                        <TextField
                                            label='CAP*'
                                            name="postal-code"
                                            variant="standard"
                                            value={postalCode}
                                            onChange={(event) => setPostalCode(event.target.value)}
                                        />
                                        <TextField
                                            className='w-full'
                                            name="city"
                                            label='Città*'
                                            variant="standard"
                                            value={city}
                                            onChange={(event) => setCity(event.target.value)}
                                        />
                                        <TextField
                                            label='Provincia*'
                                            name="province"
                                            variant="standard"
                                            value={province}
                                            onChange={(event) => setProvince(event.target.value)}
                                        />
                                    </div>
                                    <div className="flex gap-2 justify-end">
                                        <WashupOutlinedButton onClick={() => setIsEditing(false)} disabled={isLoading}>ANNULLA</WashupOutlinedButton>
                                        <WashupElevatedButton onClick={() => updateAddress()} disabled={!isComplete() || isLoading}>SALVA</WashupElevatedButton>
                                    </div>
                                </>
                            )}
                        </CardContent>
                    )
                }
            </Card>

            <Modal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style} className="w-full box-border md:w-96 max-h-screen overflow-y-auto flex justify-center items-center">
                    <div className="p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md">
                        <Typography variant="h6">Elimina l'indirizzo personale</Typography>
                        <Divider className="mt-4 ,b-4" />
                        {
                            (isLoading) ? (
                                <div className="h-48">
                                    <Loading />
                                </div>
                            ) : (
                                <div className="p-2 flex flex-col gap-4">
                                    <div>Attenzione: l'operazione è definitiva e non potrà essere annullata, continuare?</div>
                                    <div className="h-8"></div>
                                    <WashupElevatedButton color="error" onClick={() => deleteAddress()}>ELIMINA</WashupElevatedButton>
                                    <WashupOutlinedButton onClick={() => setShowDeleteModal(false)}>CHIUDI</WashupOutlinedButton>
                                </div>
                            )
                        }
                    </div>
                </Box>
                    
            </Modal>
        </>
    );
}
export default UserProfileAddress;