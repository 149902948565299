export class ItemCategory{



    constructor(data){
        this.id = data.id;
        this.name = data.label;
        this.icon_url = data.icon_url;
        this.slug = data.slug;

        if(data.item_category !== null){
            this.category = new ItemCategory(data.item_category);
        }

        this.hierarchy = [];

        var currentCategory = this.category;
        while (currentCategory !== undefined) {
            this.hierarchy = [currentCategory, ...this.hierarchy];
            currentCategory = currentCategory.category;
        }

        this.hierarchyLabel = this.hierarchy.map((e) => e.name).join("/");
        this.fullHierarchyLabel = [...this.hierarchy.map((e) => e.name), this.name].join("/");
    }
}
/*
class ItemCategory {
  late int id;
  late String name;
  late String icon;
  late String slug;

  late ItemCategory? category;
  List<String> hierarchy = [];

  String get hierarchyLabel => hierarchy.join("/");
  String get fullHierarchyLabel => [...hierarchy, name].join("/");

  ItemCategory.fromJson(Map<String, dynamic> json) {
    id = json["id"];
    name = json["label"];
    icon = json["icon"] ?? "";
    slug = json["slug"];

    category = json["item_category"] != null
        ? ItemCategory.fromJson(json["item_category"])
        : null;

    ItemCategory? currentCategory = category;
    while (currentCategory != null) {
      hierarchy = [currentCategory.name, ...hierarchy];
      currentCategory = currentCategory.category;
    }
  }

  Map<String, dynamic> toJson() => {
        'id': id,
        'name': name,
        'slug': slug,
      };
}
*/