// @ts-nocheck
import { Card, CardContent, CardActionArea, Container, TextField, Typography, Breadcrumbs, Link, Divider, IconButton, Drawer } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { apiGetItemCategories, apiGetLaundry } from '../../services/api';
import WashupContext from '../../providers/WashupProvider';
import Loading from '../_components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryListEntry from './_components/category-list-entry';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import LaundryHeader from './_components/header';
import ItemsList from './_components/items-list';
import React from 'react';
import OrderEntryEditModal from 'apps/order/_components/entry_edit_modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {Helmet} from "react-helmet";


const LaundryView = () => {
    const params = useParams();
    const navigate = useNavigate();

    const { user, cart, removeOrderEntry, setShowLoginModal } = useContext(WashupContext);

    const [isLoading, setIsLoading] = useState(false);
    const [input, setInput] = useState("");
    const [itemCategories, setItemCategories] = useState([]);
    const [itemCategory, setItemCategory] = useState();
    const [laundry, setLaundry] = useState();

    const [orderEntry, setOrderEntry] = useState();


    const getLaundry = async () => {
        setIsLoading(true);

        var res_laundry = await apiGetLaundry(params.slug);
        setLaundry(res_laundry);

        setIsLoading(false);
    }
    const getItemCategories = async () => {
        setIsLoading(true);

        var res_categories = await apiGetItemCategories();
        setItemCategories(res_categories);

        if(params.itemCategorySlug !== undefined){
            var res_itemCategory = res_categories.find((e) => e.slug === params.itemCategorySlug);
            setItemCategory(res_itemCategory);
        }else{
            setItemCategory();
        }

        setIsLoading(false);
    }


    useEffect(() =>  {
        getLaundry();
        getItemCategories();

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[params.slug, params.itemCategorySlug]);

    if(isLoading){
        return <Loading />;
    }
    if(laundry == null){
        return <></>
    }

    return (
        <> 
            <Helmet>
                <title>{laundry.meta_title}</title>
                <meta name="description" content={laundry.meta_description} />
            </Helmet>
            <LaundryHeader laundry={laundry} hideLink />
            <Container maxWidth="lg" className='mt-8'>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='hidden md:flex flex-col col-span-1 gap-2 mt-4'>
                        <Link href="#" underline="hover" color="inherit" onClick={() => navigate("/laundry/" + params.slug)}>
                            Tutte le categorie
                        </Link>
                        { (itemCategories !== undefined) && (
                            itemCategories.map((e) => {
                                if(e.hierarchyLabel === ""){
                                    return <CategoryListEntry key={"category_" + e.id} category={e} categories={itemCategories} laundry={laundry} />
                                }
                                return null;
                            })
                        )}
                    </div>
                    <div className='col-span-6 md:col-span-3 flex flex-col items-stretch gap-4'>
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            renderDotsOutside={true}
                            responsive={{
                                superLargeDesktop: {
                                  // the naming can be any, depends on you.
                                  breakpoint: { max: 4000, min: 3000 },
                                  items: 4,
                                  partialVisibilityGutter: 40 
                                },
                                desktop: {
                                  breakpoint: { max: 3000, min: 1024 },
                                  items: 4,
                                  partialVisibilityGutter: 40 
                                },
                                tablet: {
                                  breakpoint: { max: 1024, min: 464 },
                                  items: 3,
                                  partialVisibilityGutter: 40 
                                },
                                mobile: {
                                  breakpoint: { max: 464, min: 0 },
                                  items: 2,
                                  partialVisibilityGutter: 40 
                                }
                            }}
                            ssr={true} // means to render carousel on server-side.
                            infinite={false}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={[]}
                            deviceType={"desktop"}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px m-2"
                            centerMode={true}
                        >   
                            { 
                                (itemCategories !== undefined) && (
                                    itemCategories.map((e) => {
                                        if(e.hierarchyLabel === (itemCategory !== undefined ? itemCategory.fullHierarchyLabel : "")){
                                            return (
                                                <Card key={"category_" + e.id} variant='outlined' className='w-full h-full'>
                                                    <CardActionArea className='w-full h-full' onClick={() => navigate("/laundry/" + laundry.slug + "/" + e.slug)}>
                                                        <CardContent>
                                                            <div className='flex flex-col gap-4 items-center'>
                                                                <img className='w-12' src={e.icon_url} alt={e.name} />
                                                                <Typography variant='title1' className='text-center'>{ e.name }</Typography>
                                                            </div>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            )
                                        }
                                        return null;
                                    })
                                )
                            }
                        </Carousel>
                        <div className="bg-white p-6 px-8 rounded-3xl drop-shadow-md">
                            {
                                (itemCategory !== undefined) ? (
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link href="#" underline="hover" color="inherit" onClick={() => navigate("/laundry/" + params.slug)}>
                                            Tutte le categorie
                                        </Link>
                                        {
                                            itemCategory.hierarchy.map((e) => (
                                                <Link key={"category_" + e.id} href="#" underline="hover" color="inherit" onClick={() => navigate("/laundry/" + params.slug + "/" + e.slug)}>
                                                    { e.name }
                                                </Link>
                                            ))
                                        }
                                        <Typography color="text.primary">{itemCategory.name}</Typography>
                                    </Breadcrumbs>
                                ) : (
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Typography color="text.primary">Tutte le categorie</Typography>
                                    </Breadcrumbs>
                                )
                            }
                            <div className='mt-2'>
                                <TextField
                                    className='w-full'
                                    label={'cerca in "' + (itemCategory?.name ?? "Tutte le categorie") + '"'}
                                    variant="standard"
                                    onChange={(event) => setInput(event.target.value)}
                                />
                            </div>
                        </div>
                        <ItemsList laundry={laundry} input={input} category={itemCategory} />
                    </div>
                    <div className='col-span-6 md:col-span-2' id="cart">
                        <div className="bg-white m-auto p-8 rounded-3xl drop-shadow-md">
                            <Typography variant='h6' color="text.primary" className='mb-4'>Il tuo ordine</Typography>
                            <Divider />
                            {
                                (cart == null || cart.laundry.id !== laundry.id || cart.entries.length === 0) ? (
                                    <div className='flex gap-4 mt-4'>
                                        <InfoOutlinedIcon />
                                        <Typography variant='body2'>
                                            Nessun elemento nell'ordine. Trova quello che cerchi tramite il campo di ricerca o navigando tra le categorie.
                                        </Typography>
                                    </div>
                                ) : (
                                    <div className='flex flex-col gap-2'>
                                        <Divider />
                                        <div style={{ marginRight: "-30px" }}>
                                            {
                                                cart.entries.map((e) => (
                                                    <div>
                                                        <div key={"cart_entry_" + e.item.id} className='flex items-baseline gap-2'>
                                                            <Typography variant='body1'>{e.item.name}</Typography>
                                                            <Typography variant='body2'>x{e.quantity}</Typography>
                                                            <div className='flex-grow'></div>
                                                            <Typography variant='subtitle2'>€</Typography>
                                                            <Typography variant='subtitle1'>{Number(e.getTotalPrice(laundry)).toFixed(2)}</Typography>
                                                            <div className="w-20 text-center">
                                                                <IconButton
                                                                    className='mr-2'
                                                                    edge="start"
                                                                    aria-label="menu"
                                                                    onClick={() => setOrderEntry(e)}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    edge="start"
                                                                    aria-label="menu"
                                                                    onClick={() => removeOrderEntry(cart, e.item)}
                                                                >
                                                                    <DeleteIcon color="error" />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col pl-2 text-gray-400'>
                                                            {e.item.description && <Typography variant='body2'>{e.item.description}</Typography>}
                                                            {e.note && <Typography variant='body2'>Note personali: {e.note}</Typography>}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <Divider />
                                        <div style={{ marginRight: "-30px" }}>
                                            <div className='flex items-baseline gap-2'>
                                                <div className='flex-grow'></div>
                                                <Typography variant='subtitle2'>€</Typography>
                                                <Typography variant='subtitle1'>{Number(cart.getTotalPrice(false)).toFixed(2)}</Typography>
                                                <div className="w-20"></div>
                                            </div>
                                            { (cart.getCouponDiscount() > 0) && (
                                                <div className='flex items-baseline gap-2'>
                                                    <Typography variant='body1'>Codice promo</Typography>
                                                    <div className='flex-grow'></div>
                                                    <Typography variant='subtitle2'>€</Typography>
                                                    <Typography variant='subtitle1'>-{Number(cart.getCouponDiscount()).toFixed(2)}</Typography>
                                                    <div className="w-20"></div>
                                                </div>
                                            )}
                                            <div className='flex items-baseline gap-2'>
                                                <Typography variant='body1'>Spese di gestione</Typography>
                                                <div className='flex-grow'></div>
                                                <Typography variant='subtitle2'>€</Typography>
                                                <Typography variant='subtitle1'>{Number(cart.deliveryPrice).toFixed(2)}</Typography>
                                                <div className="w-20"></div>
                                            </div>
                                            <Divider />
                                            <div className='flex items-baseline gap-2'>
                                                <Typography variant='h6'>Totale</Typography>
                                                <div className='flex-grow'></div>
                                                <Typography variant='h6'>€</Typography>
                                                <Typography variant='h5'>{Number(cart.getTotalPrice()).toFixed(2)}</Typography>
                                                <div className="w-20"></div>
                                            </div>
                                        </div>
                                        <div className='h-8'></div>
                                        {(user == null) ? (
                                            <>
                                                <WashupElevatedButton onClick={() => setShowLoginModal("login")}>
                                                    <Typography variant="h6">ACCEDI o REGISTRATI</Typography>
                                                </WashupElevatedButton>

                                                <Typography variant='body2' className='text-center pt-4'>
                                                    E' possibile completare l'ordine senza effettuare l'accesso ma le tue preferenze non verranno salvate e non ti sarà possibile visualizzare i tuoi ordini precedenti.
                                                </Typography>
                                                <WashupOutlinedButton onClick={() => navigate("/cart")}>
                                                    COMPLETA COME OSPITE
                                                </WashupOutlinedButton>
                                            </>
                                        ):(
                                            <WashupElevatedButton onClick={() => navigate("/cart")}>
                                                <Typography variant="h6">COMPLETA L'ORDINE</Typography>
                                            </WashupElevatedButton>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Container>
            
            {orderEntry != null && (
                <OrderEntryEditModal laundry={laundry} item={orderEntry.item} onFinalize={(_) => setOrderEntry(null)} />
            )}
        </>
    );
  };
  
  export default LaundryView;