import { Alert, Card, CardContent, Container, Divider, FormControlLabel, Link, Radio, Switch, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import Loading from "apps/_components/loading";
import WashupContext from "providers/WashupProvider";
import { useContext, useState } from "react";
import React from "react";
import { apiSendContactForm } from "services/api";


const PageForm = (props) => {
    const { user, cart, setOrderAddress } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);



    const isComplete = () => {
        if(name == null || name === "") return false;
        if(location == null || location === "") return false;
        if(email == null || email === "") return false;
        if(mobile == null || mobile === "") return false;
        if(!isPrivacyPolicyAccepted) return false;
        return true;
    }

    const sendContactForm = async () => {
        setIsLoading(true);

        var result = await apiSendContactForm(
            props.page.id,
            name,
            location,
            email,
            mobile,
            isPrivacyPolicyAccepted
        );

        setIsSent(result);
        setHasErrors(!result);
        setIsLoading(false);
        
    }


    let showForm = false;
    let nameLabel = "Nome e Cognome";
    let locationLabel = "Città di residenza";
    let emailLabel = "Email";
    let mobileLabel = "Telefono";


    switch(props.page.id){
        case 4:
            showForm = true;
            break;
        case 14:
            nameLabel = "Ragione Sociale";
            locationLabel = "CAP";
            showForm = true;
            break;
        default:
            showForm = false;
            break;
    }


    if(!showForm) return <></>;


    return (
        <>
            <Container maxWidth="md" className='mt-8'>
                <Card className="mt-4">
                    {
                        (isLoading) ? (
                            <CardContent>
                                <div className="p-8">
                                    <Loading />
                                </div>
                            </CardContent>
                        ) : (
                            <>
                                { (isSent) ? (

                                    <CardContent>
                                        <Typography variant="h5">Contattaci per maggiori informazioni!</Typography>
                                        <p className="py-4">
                                            <Alert severity="success">Richiesta inviata con successo, ti contatteremo appena possibile per fornirti tutte le informazioni di cui hai bisogno.</Alert>
                                        </p>
                                    </CardContent>
                                    
                                ) : (

                                    <CardContent>
                                        <Typography variant="h5">Contattaci per maggiori informazioni!</Typography>
                                        <div className="mb-4">Tutti i campi sono obbligatori.</div>
                                        {(hasErrors) && (
                                            <Alert severity="error">
                                                Impossibile inviare la richiesta, ricontrolla i dati e riprova.
                                                Se il problema dovesse persistere, contattaci all'indirizzo <Link href="mailto:info@washupitalia.it" underline="hover" target="_blank">info@washupitalia.it</Link>.
                                            </Alert>
                                        )}
                                        <div className="h-4"></div>
                                        <TextField
                                            className='w-full'
                                            name="name"
                                            label={nameLabel}
                                            variant="standard"
                                            value={name}
                                            onChange={(event) => setName(event.target.value)}
                                        />
                                        <div className='flex flex-col md:flex-row gap-4 mt-4'>
                                            <TextField
                                                className='w-full'
                                                name="location"
                                                label={locationLabel}
                                                variant="standard"
                                                value={location}
                                                onChange={(event) => setLocation(event.target.value)}
                                            />
                                        </div>
                                        <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                                            <TextField
                                                className='w-full'
                                                label={emailLabel}
                                                name="email"
                                                variant="standard"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                            <TextField
                                                className='w-full'
                                                name="mobile"
                                                label={mobileLabel}
                                                variant="standard"
                                                value={mobile}
                                                onChange={(event) => setMobile(event.target.value)}
                                            />
                                        </div>
                                        <FormControlLabel control={<Switch checked={isPrivacyPolicyAccepted} onChange={(event) => setIsPrivacyPolicyAccepted(event.target.checked)} />} label={<>
                                            Acconsento al trattamento dei miei dati personali e dichiaro di aver preso visione della&nbsp;
                                            <Link key="privacy-policy" href="https://www.washupitalia.it/privacy-policy" underline="hover" target="_blank" >Privacy Policy</Link>.
                                        </>} />
                                        <div className="h-4"></div>
                                        <WashupElevatedButton className="w-full" onClick={() => sendContactForm()} disabled={!isComplete() || isLoading}>INVIA E TI RICONTATTEREMO NOI!</WashupElevatedButton>
                                    </CardContent>
                                ) }
                            </>
                        )
                    }
                </Card>
            </Container>
        </>
    );
}
export default PageForm;