import moment from 'moment';

const statusTranslations = {
    "Canceled": "Cancellato",
    "Cart": "Carrello",
    "Waiting payment": "In attesa di pagamento",
    "Payment refused": "Pagamento rifiutato",
    "Payment accepted": "Pagamento accettato",
    "Waiting pickup carrier assignment": "In attesa di assegnazione fattorino per il ritiro presso il cliente",
    "Pickup carrier assigned": "Fattorino per il ritiro presso il cliente assegnato",
    "Waiting for pickup at the customer": "In attesa di ritiro presso il cliente",
    "Picked up at the customer": "Ritiro effettuato presso il cliente",
    "Waiting for delivery to laundry": "In attesa di consegna presso la lavanderia",
    "Waiting for customer feedback": "In attesa di riscontro dal cliente",
    "Delivered to laundry": "Consegna effettuata presso la lavanderia",
    "Waiting for processing by laundry": "In attesa di lavorazione presso la lavanderia",
    "Processing by laundry": "In lavorazione presso la lavanderia",
    "Processed by laundry": "Lavorazione presso la lavanderia terminata",
    "Waiting for delivery carrier assignment": "In attesa di assegnazione fattorino per il ritiro presso la lavanderia",
    "Delivery carrier assigned": "Fattorino per il ritiro presso la lavanderia assegnato",
    "Waiting for pickup at the laundry": "In attesa di ritiro presso la lavanderia",
    "Picked up at the laundry": "Ritiro effettuato presso la lavanderia",
    "Waiting for delivery to the customer": "In attesa di riconsegna presso il cliente",
    "Delivered to the customer": "Riconsegna effettuata presso il cliente",
    "Order completed": "Ordine completato",
};

class Status {
    constructor(data){
        this.id = data.id;
        this.status = data.status;
        this.color = data.color;
        this.icon = data.icon;
        this.label = statusTranslations[data.label];
        this.description = data.description;

        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');
    }
}
class OrderStatus {
    constructor(data){
        this.id = data.id;
        this.comment = data.comment;

        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');

        this.status = new Status(data.status);
    }
}
export default OrderStatus;