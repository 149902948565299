import { Container, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { WashupHeaderSlim } from 'apps/_components/header';
import Loading from 'apps/_components/loading';
import WashupContext from 'providers/WashupProvider';
import PageCard from './_components/card';
import React from 'react';


const NewsView = () => {

    const { pages } = useContext(WashupContext);

    useEffect(() =>  {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    if(pages?.news == null){
        return <Loading />
    }

    return (
        <> 
            <WashupHeaderSlim>
                <div className='flex flex-col items-start'>
                    <Typography variant='h4' className='p-4'>Tutte le novità Washup</Typography>
                </div>
            </WashupHeaderSlim>

            <Container maxWidth="sm" className='mt-8'>
                {
                    (pages?.news == null) ? (
                        <Loading />
                    ):(
                        <div className='flex flex-col gap-4'>
                            {
                                pages?.news.map((e) => <PageCard page={e} />)
                            }
                        </div>
                    )
                }
            </Container>
        </>
    );
  };
  
  export default NewsView;