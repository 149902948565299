import { Card, CardContent, Divider, Typography } from "@mui/material";
import Loading from "apps/_components/loading";
import WashupContext from "providers/WashupProvider";
import React, { useContext } from "react";
import OrderCoupon from "./coupon";


const OrderOverview = (props) => {

    const { cart } = useContext(WashupContext);

    if(cart == null && props.order == null) {
        return <Loading />
    }

    var order = cart ?? props.order;

    return (
        <>
            <Card>
                <CardContent className='flex flex-col gap-2'>
                    {
                        order.entries.map((e) => (
                            <div>
                                <div key={"order_entry_" + e.item.id} className='flex items-baseline gap-2'>
                                    <Typography variant='body1'>{e.item.name}</Typography>
                                    <Typography variant='body2'>x{e.quantity}</Typography>
                                    <div className='flex-grow'></div>
                                    <Typography variant='subtitle2'>€</Typography>
                                    <Typography variant='subtitle1'>{Number(e.getTotalPrice(order.laundry)).toFixed(2)}</Typography>
                                </div>
                                <div className='flex flex-col pl-2'>
                                    {e.item.description && <Typography variant='body2'>{e.item.description}</Typography>}
                                    {e.note && <Typography variant='body2'>Note personali: {e.note}</Typography>}
                                </div>
                            </div>
                        ))
                    }
                    <Divider />
                    <div className='flex items-baseline gap-2'>
                        <div className='flex-grow'></div>
                        <Typography variant='subtitle2'>€</Typography>
                        <Typography variant='subtitle1'>{Number(order.getTotalPrice(false)).toFixed(2)}</Typography>
                    </div>
                    { (order.getCouponDiscount() > 0) && (
                        <div className='flex items-baseline gap-2'>
                            <Typography variant='body1'>Codice promozionale (-{ order.coupon.amount ? "€ " + Number(order.coupon.amount).toFixed(2) : Number(order.coupon.percentage).toFixed(2) + " %" })</Typography>
                            <div className='flex-grow'></div>
                            <Typography variant='subtitle2'>€</Typography>
                            <Typography variant='subtitle1'>-{Number(order.getCouponDiscount()).toFixed(2)}</Typography>
                        </div>
                    )}
                    <div className='flex items-baseline gap-2'>
                        <Typography variant='body1'>Spese di gestione</Typography>
                        <div className='flex-grow'></div>
                        <Typography variant='subtitle2'>€</Typography>
                        <Typography variant='subtitle1'>{Number(order.deliveryPrice).toFixed(2)}</Typography>
                    </div>
                    <Divider />
                    <div className='flex items-baseline gap-2'>
                        <Typography variant='h6'>Totale</Typography>
                        <div className='flex-grow'></div>
                        <Typography variant='h6'>€</Typography>
                        <Typography variant='h5'>{Number(order.getTotalPrice()).toFixed(2)}</Typography>
                    </div>
                    
                    {props.showCoupon && (
                        <>
                            <Divider />
                            <div className='pt-4 pb-4'>
                                <OrderCoupon />
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
}
export default OrderOverview;