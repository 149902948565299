import { ItemCategory } from "./item-category";

export class Item{

    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.priority = data.priority;
        this.description = data.description;
        this.tags = data.tags;
        this.icon_url = data.icon_url;
        this.commission = data.commission;

        this.categories = data.item_categories.map((e) => new ItemCategory(e));
    }
}

/*
class Item {
  late int id;
  late String name;
  late String? description;
  late String? tags;
  late String icon;
  late double commission;

  late List<ItemCategory> categories = [];

  Item.fromJson(Map<String, dynamic> json) {
    id = json["id"];
    name = json["name"];
    description = json["description"];
    tags = json["tags"];
    icon = json["icon"];
    commission = json["commission"];

    if (json["item_categories"] != null) {
      categories = List<ItemCategory>.from(
          json["item_categories"].map((e) => ItemCategory.fromJson(e)));
    }
  }
}
*/