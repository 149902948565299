import { CircularProgress, LinearProgress } from "@mui/material";
import React from "react";

const Loading = () => {
    
    return (
        <div className="w-full h-full flex items-center justify-center p-18">
            <div className="w-48 relative flex flex-col gap-2">
                <img src="/images/washup_web_inline.png" alt="WashUp" />
                <LinearProgress />
                {/*
                <CircularProgress color="primary" className="absolute" size={80} sx={{ left: "50%", top: "50%", marginLeft: "-40px", marginTop: "-40px" }} />
                <img className="absolute" style={{animation: 'spin 0.7s linear infinite', height: "50px", width: "36px", left: "50%", top: "50%", marginLeft: "-18px", marginTop: "-25px" }} src="/images/washup_web_icon.png" alt="WashUp"/>
                */}
            </div>
        </div>
    );
}

export default Loading;