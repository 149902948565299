import moment from 'moment';

class OrderPayment {
    constructor(data){
        this.refId = data.ref_id;
        this.amount = data.amount;
        this.isPaid = data.is_paid;

        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');
    }

}
export default OrderPayment;