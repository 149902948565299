import moment from 'moment';

export class Coupon {

    constructor(data){
        this.id = data.id;
        this.amount = data.amount;
        this.percentage = data.percentage;
        this.uses = data.uses;
        this.code = data.code;
    }
  }