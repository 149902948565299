// @ts-nocheck
import { Alert, Container, TextField, Typography } from '@mui/material';
import { useContext, useEffect} from 'react';
import WashupContext from '../../providers/WashupProvider';
import { WashupHeaderSlim } from '../_components/header';
import Loading from 'apps/_components/loading';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiResendVerify, apiSendPasswordRecovery, apiResetPassword } from 'services/api';
import { useState } from 'react';
import { WashupElevatedButton } from 'apps/_components/button';

const PasswordRecoveryView = (props) => {
    const { user, setShowLoginModal, logout } = useContext(WashupContext);
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate();
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ token ] = useState(searchParams.get('token'));
    const [ email, setEmail ] = useState(searchParams.get('email'));
    const [ password, setPassword ] = useState();
    const [ passwordConfirm, setPasswordConfirm ] = useState();

    const [ isEmailSent, setIsEmailSent ] = useState(false);
    const [ isPasswordReset, setIsPasswordReset ] = useState(false);
    const [ emailSentError, setEmailSentError ] = useState(false);
    const [ passwordResetError, setPasswordResetError ] = useState(false);


    useEffect(() =>  {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);
    
    const sendLink = () => {
        setIsLoading(true);
        setEmailSentError(false);
        apiSendPasswordRecovery(email).then((result) => {
            if(result){
                setEmail();
                setIsEmailSent(true);
            }else{
                setEmailSentError(true)
                setIsEmailSent(false);
            }
            setIsLoading(false);
        });
    }
    
    const resetPassword = () => {
        setIsLoading(true);
        setPasswordResetError(false);
        apiResetPassword(token, email, password, passwordConfirm).then((result) => {
            if(result){
                setEmail();
                setIsPasswordReset(true);
            }else{
                setPasswordResetError(true);
                setIsPasswordReset(false);
            }
            setIsLoading(false);
        });
    }

    const isEmailValid = () => {
        return email?.length >= 5;
    }
    const isPasswordValid = () => {
        if(password?.length < 6) return false;
        if(password !== passwordConfirm) return false;
        return true;
    }

    if(isLoading){
        return <Loading />;
    }

    return (
        <> 
        <WashupHeaderSlim>
            <div className='flex gap-4 items-center'>
                <Typography variant='h4' className='p-4'>Recupera la password</Typography>
            </div>
        </WashupHeaderSlim>
        <Container maxWidth="md" className='mt-8'>
            { (token == null) ? (
                <div className='flex flex-col gap-4'>
                    { (isEmailSent) && (
                        <p>
                            <Alert severity="success">Istruzioni inviate con successo, verifica la tua casella email.</Alert>
                        </p>
                    )}
                    { (emailSentError) && (
                        <p>
                             <Alert severity="error">Impossibile inviare le istruzioni, ricontrolla i dati e riprova.</Alert>
                        </p>
                    )}
                    <p>
                        Inserisci l'indirizzo email con cui effettui l'accesso e ti invieremo un'email con le istruzioni per il recupero della password. 
                    </p>
                    <div className='mt-8'>
                        <TextField
                            className='w-full'
                            name="email"
                            label="Email*"
                            variant="standard"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <p className='flex gap-2 mt-4'>
                        <WashupElevatedButton variant="contained" disabled={!isEmailValid()} onClick={() => sendLink()}>INVIA</WashupElevatedButton>
                    </p>
                </div>
            ):(
                <>
                    {(isPasswordReset && !passwordResetError) ? (
                        <div className='flex flex-col gap-4'>
                            <p>
                                <Alert severity="success">La tua password è stata Reimpostata con successo!</Alert>
                            </p>
                            <p>
                                Puoi utilizzarla fin da subito per effettuare l'accesso.
                            </p>
                            <p className='flex gap-2 mt-4'>
                                <WashupElevatedButton variant="contained" onClick={() => setShowLoginModal("login")}>ACCEDI</WashupElevatedButton>
                            </p>
                        </div>

                    ):(
                        <div className='flex flex-col gap-4'>
                            { (passwordResetError) && (
                                <p>
                                    <Alert severity="error">Impossibile reimpostare la password, ricontrolla i dati e riprova.</Alert>
                                </p>
                            )}
                            <p>
                                Inserisci la nuova password per il tuo account. 
                            </p>
                            <div className='mt-8 flex gap-4'>
                                <TextField
                                    className='w-full'
                                    name="password"
                                    label="Password*"
                                    variant="standard"
                                    type="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                <TextField
                                    className='w-full'
                                    name="password-confirm"
                                    label="Conferma password*"
                                    variant="standard"
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={(event) => setPasswordConfirm(event.target.value)}
                                />
                            </div>
                            <p className='flex gap-2 mt-4'>
                                <WashupElevatedButton variant="contained" disabled={!isPasswordValid()} onClick={() => resetPassword()}>REIMPOSTA LA PASSWORD</WashupElevatedButton>
                            </p>
                        </div>
                    )}
                </>
            )}
        </Container>
        </>
    );
  };
  
  export default PasswordRecoveryView;