import { Container, Divider, Link, Typography } from "@mui/material";
import { useContext } from "react";
import WashupContext from "providers/WashupProvider";
import { useNavigate } from "react-router-dom";
import React from "react";
import ReactHtmlParser from 'react-html-parser';

const WashupFooter = (props) => {
    const { pages } = useContext(WashupContext);
    const navigate = useNavigate();

    return (
        <div className="bg-gray-200">
            <Container maxWidth="lg">
                <div className='p-2 md:p-8 py-8 grid grid-cols-6 gap-4'>
                    <div className="col-span-6 md:col-span-2 mb-8 md:mb-0">
                        <img src="/images/washup_web_side.png" className='hidden md:block h-14' alt="WashUp" />
                        <img src="/images/washup_web_inline.png" className='block md:hidden h-10' alt="WashUp" />
                    </div>

                    {
                        ( pages?.["footer"] != null ) && (
                            pages?.["footer"].map((column) => (
                                <div className="flex flex-col col-span-6 md:col-span-1 gap-2 mb-8 md:mb-0">
                                    <Typography variant="subtitle1">{ column.title }</Typography>
                                    <Divider />
                                    <Typography variant="body2">
                                        { ReactHtmlParser(column.body) }
                                    </Typography>
                                    {
                                        ( pages?.[column.slug] != null ) && (
                                            pages?.[column.slug].map((link) => (
                                                <Link key={"footer_link_" + link.slug} href="" underline="hover" onClick={() => navigate('/page/' + link.slug)}>
                                                    <Typography variant="body2">
                                                        {link.title}
                                                    </Typography>
                                                </Link>
                                            ))
                                        )
                                    }
                                </div>
                            ))
                        )
                    }
                </div>
            </Container>
        </div>
    )
}

export default WashupFooter;