// @ts-nocheck
import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import WashupContext from "../../../providers/WashupProvider";
import { Alert, Box, Divider, TextField, Typography } from "@mui/material";
import { WashupOutlinedButton, WashupElevatedButton } from 'apps/_components/button';
import Loading from "../../_components/loading";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const LoginModal = () => {
    const { login, setShowLoginModal } = useContext(WashupContext);
    const navigate = useNavigate();

    const [ isLoading, setIsLoading ] = useState(false);

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const [ errors, setErrors ] = useState();
    const [ failed, setFailed ] = useState(false);


    const tryLogin = async () => {

        setErrors();
        setFailed(false);

        var validation = {};
        var isValid = true;
        if(email == null || email === "" || email.length < 3){
            validation.email = "Il campo email è richiesto";
            isValid = false;
        }
        if(password == null || password === "" || password.length < 3){
            validation.password = "Il campo password è richiesto";
            isValid = false;
        }
        if(isValid){
            setIsLoading(true);
            var result = await login(email, password);
            if(result){
                setShowLoginModal();
            }else{
                setFailed(true);
            }
            setIsLoading(false);
        }else{
            setErrors(validation);
        }
    }

    const recoverPassword = () => {
        setShowLoginModal();
        navigate('/profile/password-recovery');
    }

    return (
        <Box sx={style} className="w-full box-border md:w-96 max-h-screen overflow-y-auto flex justify-center items-center">
            <div className="p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md">
                <Typography variant="h6">Effettua l'accesso</Typography>
                <Divider className="mt-4 ,b-4" />
                {
                    (isLoading) ? (
                        <div className="h-48">
                            <Loading />
                        </div>
                    ) : (
                        <div className="p-2 flex flex-col gap-4">
                            <TextField
                                className='w-full'
                                name="email"
                                label='Email*'
                                variant="standard"
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}

                                error={errors?.email != null}
                                helperText={errors?.email}
                            />
                            <TextField
                                className='w-full'
                                name="password"
                                label='Password*'
                                variant="standard"
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}

                                error={errors?.password != null}
                                helperText={errors?.password}
                            />

                            { (failed) && (
                                <Alert severity="error">Accesso fallito, ricontrolla i dati e riprova.</Alert>
                            )}
                            <div className="h-8"></div>
                            <WashupElevatedButton onClick={() => tryLogin()}>Accedi</WashupElevatedButton>
                            <WashupOutlinedButton href="/profile/password-recovery">Recupera la password</WashupOutlinedButton>
                            <Divider />
                            <Typography variant="subtitle2" className="text-center">Non hai ancora un account?</Typography>
                            <WashupElevatedButton onClick={() => setShowLoginModal("register")}>Registrati</WashupElevatedButton>
                            <div className="h-8"></div>
                            <WashupOutlinedButton onClick={() => setShowLoginModal()}>Chiudi</WashupOutlinedButton>
                        </div>
                    )
                }
            </div>
        </Box>
    )

}
export default LoginModal;