import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CategoryListEntry = (props) => {
    const navigate = useNavigate();


    return (
        <div className='col-span-1 flex flex-col gap-2 pl-2'>
            <Link 
                href="#"
                underline="hover"
                color="inherit"

                onClick={() => navigate("/laundry/" + props.laundry.slug + "/" + props.category.slug)}
            >
                { props.category.name }
            </Link>
            <div className='col-span-1 flex flex-col gap-1'>
                {props.categories.map((e) => {
                    if(e.hierarchyLabel === props.category.fullHierarchyLabel){
                        return <CategoryListEntry key={"category_" + e.id} category={e} categories={props.categories} laundry={props.laundry} />
                    }
                    return null;
                })}
            </div>
        </div>
    );

}

export default CategoryListEntry;