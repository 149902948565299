import { Button, Card, CardContent, Container, Divider, IconButton, Link, Typography } from '@mui/material';
import { useContext, useEffect} from 'react';
import WashupContext from '../../providers/WashupProvider';
import { WashupHeaderSlim } from '../_components/header';
import DeleteIcon from "@mui/icons-material/Delete";
import { WashupOutlinedButton } from 'apps/_components/button';
import Loading from 'apps/_components/loading';
import React from 'react';
import UserProfileAddress from './_components/profile-address';
import UserProfileInfo from './_components/profile-info';
import UserProfilePassword from './_components/profile-password';

const UserProfileView = () => {
    const { user } = useContext(WashupContext);

    useEffect(() =>  {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);
    
    if(user == null){
        return <Loading />;
    }

    return (
        <> 
            <WashupHeaderSlim>
                <div className='flex gap-4 items-center'>
                    <Typography variant='h4' className='p-4'>Il tuo profilo</Typography>
                </div>
            </WashupHeaderSlim>
            <Container maxWidth="lg" className='mt-8'>
                <div className='grid grid-cols-6 gap-4'>
                    <div className='hidden md:flex flex-col gap-2 mt-4 sticky justify-start'>
                        <Link underline="hover" color="inherit" href="#user">Utente</Link>
                        <Link underline="hover" color="inherit" href="#address">Indirizzi</Link>
                        <Link underline="hover" color="inherit" href="#payments">Pagamenti</Link>
                    </div>
                    <div className='col-span-6 md:col-span-3 bg-white p-8 rounded-3xl drop-shadow-md'>
                        <div id="user" className='mb-8'>
                            <div className='flex w-full gap-4 items-end'>
                                <Typography variant='h5' className='flex-grow'>{ user.name }</Typography>
                            </div>
                            <UserProfileInfo />
                            <UserProfilePassword />
                        </div>
                        <div id="address" className='mb-8'>
                            <div className='flex w-full gap-4 items-end'>
                                <Typography variant='h5' className='flex-grow'>Indirizzi</Typography>
                            </div>
                            {
                                user.address.map((address) => (
                                    <UserProfileAddress address={address} />
                                ))
                            }
                        </div>

                    </div>
                    <div className='col-span-6 md:col-span-2'>
                        <div className="flex flex-col gap-2 bg-white m-auto p-8 rounded-3xl drop-shadow-md">
                            <Typography variant='h6' color="text.primary" className='mb-4'>Azioni</Typography>
                            <Divider />
                            <WashupOutlinedButton disabled>ESPORTA DATI PERSONALI</WashupOutlinedButton>
                            <Button disabled color="error" variant="contained" sx={{ borderRadius: 100 }}>ELIMINA PROFILO</Button>
                        </div>
                    </div>

                </div>
            </Container>
        </>
    );
  };
  
  export default UserProfileView;