import { Divider, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from "./button";
import { useNavigate } from "react-router-dom";
import React from "react";

const WashupMenuDrawer = () => {
    const navigate = useNavigate();

    return (
        <div className="w-80">
            <div className="flex flex-col gap-4 p-8">
                <Typography variant="h6" className="text-center">Menu</Typography>
                <WashupElevatedButton onClick={() => navigate('/')}>HOME</WashupElevatedButton>
                <Divider className="mt-8 mb-8" />
                <WashupOutlinedButton onClick={() => navigate('/news')}>News</WashupOutlinedButton>
                <WashupOutlinedButton onClick={() => navigate('/page/lavora-con-noi')}>Lavora con noi</WashupOutlinedButton>
            </div>
        </div>
    )
}

export default WashupMenuDrawer;