import { Card, CardContent, Divider, Radio, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import { useContext, useState } from "react";
import WashupContext from "../../../../providers/WashupProvider";
import UserAddress from "../../../../_models/user-address";
import Loading from "../../../_components/loading";
import React from "react";


const CartStepAddress = (props) => {
    const { user, cart, setOrderAddress } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);


    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");


    const setNewAddress = async () => {
        setIsLoading(true);
        var userAddress = new UserAddress({
            address: address,
            postal_code: postalCode,
            city: city,
            province: province,
        });
        await setOrderAddress(cart, props.type, userAddress);
        setShowAddNew(false)
        setIsLoading(false);
    }

    const isComplete = () => {
        if(address == null || address === "") return false;
        if(postalCode == null || postalCode.length !== 5) return false;
        if(city == null || city === "") return false;
        if(province == null || province === "") return false;
        return true;
    }

    return (
        <>
            {
                user.address.map((e) => (
                    <>
                        <div key={"address_" + e.id} className='flex gap-4 p-2'>
                            <Radio checked={e.id === props.selectedAddressId} onChange={() => setOrderAddress(cart, props.type, e)} />
                            <div>
                                <Typography variant='subtitle1'>{e.address}</Typography>
                                <Typography variant='body2'>{e.postalCode} {e.city} ({e.province})</Typography>
                            </div>
                        </div>
                        <Divider className='mt-4 mb-2' />
                    </>
                ))
            }
            {(showAddNew || user.address.length === 0) ? (
                <Card className="mt-4">
                    {
                        (isLoading) ? (
                            <CardContent>
                                <div className="p-8">
                                    <Loading />
                                </div>
                            </CardContent>
                        ) : (
                            <CardContent>
                                <TextField
                                    className='w-full'
                                    name="address"
                                    label='Indirizzo*'
                                    variant="standard"
                                    onChange={(event) => setAddress(event.target.value)}
                                />
                                <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                                    <TextField
                                        label='CAP*'
                                        name="postal-code"
                                        variant="standard"
                                        onChange={(event) => setPostalCode(event.target.value)}
                                    />
                                    <TextField
                                        className='w-full'
                                        name="city"
                                        label='Città*'
                                        variant="standard"
                                        onChange={(event) => setCity(event.target.value)}
                                    />
                                    <TextField
                                        label='Provincia*'
                                        name="province"
                                        variant="standard"
                                        onChange={(event) => setProvince(event.target.value)}
                                    />
                                </div>
                                <WashupElevatedButton className="w-full" onClick={() => setNewAddress()} disabled={!isComplete() || isLoading}>INSERISCI E SELEZIONA</WashupElevatedButton>
                            </CardContent>
                        )
                    }
                </Card>
            ):(
                <Card className="mt-4">
                    <CardContent>
                        <WashupElevatedButton className="w-full" onClick={() => setShowAddNew(true)}>INSERISCI UN NUOVO INDIRIZZO</WashupElevatedButton>
                    </CardContent>
                </Card>
            )}
            <div className='flex justify-end mt-8 gap-4'>
                <WashupOutlinedButton onClick={() => props.setActiveStep(props.activeStep - 1)}>Indietro</WashupOutlinedButton>
                <WashupElevatedButton onClick={() => props.setActiveStep(props.activeStep + 1)} disabled={isLoading || props.selectedAddressId == null}>Avanti</WashupElevatedButton>
            </div>
        </>
    );
}
export default CartStepAddress;