// @ts-nocheck
import { Alert, Container, Link, TextField, Typography } from '@mui/material';
import { useContext, useEffect} from 'react';
import WashupContext from '../../providers/WashupProvider';
import { WashupHeaderSlim } from '../_components/header';
import Loading from 'apps/_components/loading';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { apiResendVerify, apiSendPasswordRecovery, apiResetPassword, apiGetOrder, apiGetPaymentLinks } from 'services/api';
import { useState } from 'react';
import { WashupElevatedButton } from 'apps/_components/button';

const OrderPaymentRedirectView = (props) => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [link, setLink] = useState();

    const goToPayment = async () => {
        setIsLoading(true);

        var payment = await apiGetPaymentLinks(params.id, params.paymentId);

        var link = payment.links.find((e) => e.rel === "payer-action");
        setLink(link.href);
        window.location.href = link.href;
        setIsLoading(false);
        
    }

    useEffect(() =>  {
        goToPayment();

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    return (
        <> 
        <WashupHeaderSlim>
            <div className='flex gap-4 items-center'>
                <Typography variant='h4' className='p-4'>Vai al pagamento</Typography>
            </div>
        </WashupHeaderSlim>
        <Container maxWidth="md" className='mt-8'>
            { (isLoading) ? (
                <Loading />
            ):(
                <>
                    {(link) ? (
                        <p>Se non sei stato reindirizzato automaticamente, clicca <Link href={link} underline="hover" target="_blank" >QUI</Link>.</p>
                    ):(
                        <Alert severity="error">
                            Non è stato possibile trovare il pagamento.<br/>
                            Il pagamento potrebbe già essere stato effettuato o i dati non sono corretti.<br/>
                            <br/>
                            Riprova o contattaci all'indirizzo <Link href="mailto:info@washupitalia.it" underline="hover" target="_blank">info@washupitalia.it</Link>.
                        </Alert>
                    ) }
                </>
                    
            )}
        </Container>
        </>
    );
  };
  
  export default OrderPaymentRedirectView;