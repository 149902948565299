// @ts-nocheck
import { Alert, Card, CardContent, IconButton, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import Loading from "apps/_components/loading";
import WashupContext from "providers/WashupProvider";
import { useContext, useState } from "react";
import React from "react";
import { Edit } from '@mui/icons-material';
import { apiUpdateUserInfo } from "services/api";


const UserProfileInfo = (props) => {
    const { user, getUser } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [mobile, setMobile] = useState(user.mobile);

    const [ errors, setErrors ] = useState();
    const [ failed, setFailed ] = useState(false);

    const startEdit = () => {
        setName(user.name);
        setEmail(user.email);
        setMobile(user.mobile);
        
        setIsEditing(true);
    }

    const tryUpdate = async () => {

        setErrors();
        setFailed(false);

        var validation = {};
        var isValid = true;
        if(name == null || name < 3){
            validation.lastName = "Il campo Cognome è richiesto";
            isValid = false;
        }
        if(email == null || email.length < 3 || !String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            validation.email = "Il campo Email è richiesto";
            isValid = false;
        }
        /*
        if(mobile == null || mobile.length < 3){
            validation.mobile = "Il campo Telefono è richiesto";
            isValid = false;
        }
        */
        if(isValid){
            setIsLoading(true);
            var result = await apiUpdateUserInfo(name, email, mobile);
            if(result){
                await getUser();
                setIsEditing(false);
            }else{
                setFailed(true);
            }
            setIsLoading(false);
        }else{
            setErrors(validation);
        }
    }

    if(user == null){
        return <></>;
    }

    return (
        <>
            <Card className="mt-4">
                {
                    (isLoading) ? (
                        <CardContent>
                            <div className="p-8">
                                <Loading />
                            </div>
                        </CardContent>
                    ) : (
                        <CardContent>
                            {!isEditing ? (
                                <div className="flex gap-2">
                                    <div className='flex flex-col gap-2 flex-grow'>
                                        <Typography variant='subtitle1'>Email: {user.email}</Typography>
                                        <Typography variant='subtitle1'>Telefono {user.mobile}</Typography>
                                        <Typography variant='subtitle1'>Iscritto dal { user.createdAt }</Typography>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <IconButton aria-label="edit" onClick={() => startEdit()} >
                                            <Edit />
                                        </IconButton>
                                    </div>
                                </div>
                            ):(
                                <div className="flex flex-col gap-4">
                                    <TextField
                                        className='col-span-2 md:col-span-1'
                                        name="last_name"
                                        label='Nome e Cognome*'
                                        variant="standard"
                                        type="text"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
        
                                        error={errors?.lastName != null}
                                        helperText={errors?.lastName}
                                    />
                                    <TextField
                                        className='col-span-2'
                                        name="email"
                                        label='Email*'
                                        variant="standard"
                                        type="email"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
        
                                        error={errors?.email != null}
                                        helperText={errors?.email}
                                    />
                                    <TextField
                                        className='col-span-2'
                                        name="mobile"
                                        label='Telefono'
                                        variant="standard"
                                        type="text"
                                        value={mobile}
                                        onChange={(event) => setMobile(event.target.value)}
        
                                        error={errors?.mobile != null}
                                        helperText={errors?.mobile}
                                    />
                                    {email !== user.email && (
                                        <div className="mt-4">
                                            <Alert severity="warning">Attenzione: modificando il campo email sarà necessare effettuare nuovamente la verifica del nuovo indirizzo.</Alert>

                                        </div>
                                    )}
                                    <div className="flex gap-2 justify-end mt-4">
                                        <WashupOutlinedButton onClick={() => setIsEditing(false)} disabled={isLoading}>ANNULLA</WashupOutlinedButton>
                                        <WashupElevatedButton onClick={() => tryUpdate()}>SALVA</WashupElevatedButton>
                                    </div>
                                </div>
                            )}
                        </CardContent>
                    )
                }
            </Card>
        </>
    );
}
export default UserProfileInfo;