// @ts-nocheck
import { Card, CardActionArea, CardContent, Modal, Typography } from "@mui/material";
import { useContext, useState } from "react";
import WashupContext from "../../../providers/WashupProvider";
import OrderDiscardModal from "apps/order/_components/discard-modal";
import React from "react";
import OrderEntryEditModal from 'apps/order/_components/entry_edit_modal';

const ItemsList = (props) => {
    const { cart, showDiscardCartModal, setShowDiscardCartModal } = useContext(WashupContext); 

    const [item, setItem] = useState();
    const [entryItem, setEntryItem] = useState();

      
    const addOrderEntry = (selectedItem) => {
        setShowDiscardCartModal(false);
        
        if(cart !=null && cart.laundry.id !== props.laundry.id && cart.entrtiesCount > 0){
            setItem(selectedItem);
            setShowDiscardCartModal(true);
        }else{
            setEntryItem(selectedItem);
        }
    }
    const discardCart = (discardCart) => {
        setShowDiscardCartModal(false);
        
        if(discardCart){
            setEntryItem(item);
        }
    }

    const search = props.input?.toLowerCase().replace(" ","");

    return (
        <>
            <div className='flex flex-col gap-4'>
                { 
                    (props.laundry != null && props.laundry.items != null) && (
                        props.laundry.items.filter((e) => (
                            (props.category == null || e.item.categories.find((e) => e.id === props.category.id)) &&
                            (e.item.name?.toLowerCase().replace(" ","").includes(search) || e.item.tags?.toLowerCase().replace(" ","").includes(search))
                        )).sort((a,b) => b.item.priority - a.item.priority).map((e) => {
                            return (
                                <Card key={"category_" + e.item.id} variant='outlined'>
                                    <CardActionArea onClick={() => {
                                        addOrderEntry(e.item);
                                    }}>
                                        <CardContent>
                                            <div className='flex gap-4 items-center'>
                                                <img className='w-8 md:w-12' src={e.item.icon_url} alt={e.item.name} />
                                                <div className='flex-grow'>
                                                    <Typography variant='body1'>{ e.item.name }</Typography>
                                                    <Typography variant='body2' className="text-gray-400">{ e.item.description }</Typography>
                                                </div>
                                                <div className='flex gap-2'>
                                                    <Typography variant='h6' className='text-center'>€</Typography>
                                                    <Typography variant='h5' className='text-center'>{ Number(e.finalPrice).toFixed(2) }</Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )
                        })
                    )
                }
            </div>
            
            {entryItem != null && (
                <OrderEntryEditModal laundry={props.laundry} item={entryItem} quantity={1} onFinalize={(_) => setEntryItem(null)} />
            )}
            

            <Modal
                open={showDiscardCartModal}
                onClose={() => setShowDiscardCartModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >

                <>
                    <OrderDiscardModal onDiscard={(discard) => discardCart(discard)} />
                </>
            </Modal>
        </>
    )
}
export default ItemsList;