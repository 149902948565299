// @ts-nocheck
import { useContext, useState } from "react";
import WashupContext from "../../../providers/WashupProvider";
import { Alert, Box, Divider, TextField, Typography, FormGroup, Link, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import Loading from "../../_components/loading";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const RegisterModal = () => {
    const { register, setShowLoginModal } = useContext(WashupContext);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ mobile, setMobile ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ passwordConfirmation, setPasswordConfirmation ] = useState("");
    
    const [ isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted ] = useState(false);

    const [ errors, setErrors ] = useState();
    const [ failed, setFailed ] = useState(false);

    //const passwordRegexp = new RegExp("^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$");
    const passwordRegexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;

    const tryRegister = async () => {

        setErrors();
        setFailed(false);

        var validation = {};
        var isValid = true;
        if(firstName == null || firstName.length < 3){
            validation.firstName = "Il campo Nome è richiesto";
            isValid = false;
        }
        if(lastName == null || lastName.length < 3){
            validation.lastName = "Il campo Cognome è richiesto";
            isValid = false;
        }
        if(email == null || email.length < 3){
            validation.email = "Il campo Email è richiesto";
            isValid = false;
        }
        /*
        if(mobile == null || mobile.length < 3){
            validation.mobile = "Il campo Telefono è richiesto";
            isValid = false;
        }
        */

        if(password == null || !passwordRegexp.test(password)){
            validation.password = "La password dev'essere di almeno 6 caratteri e deve contenere almeno una lettera, un numero e un carattere speciale";
            isValid = false;
        }
        if(passwordConfirmation !== password){
            validation.passwordConfirmation = "La Password di conferma non corrisponde";
            isValid = false;
        }
        if(!isPrivacyPolicyAccepted){
            validation.terms = "Per continuare è necessario accettare i termini del servizio";
            isValid = false;
        }
        if(isValid){
            setIsLoading(true);
            var result = await register(firstName + " " + lastName, email, mobile, password, passwordConfirmation);
            if(result){
                setShowLoginModal();
            }else{
                setFailed(true);
            }
            setIsLoading(false);
        }else{
            setErrors(validation);
        }
    }

    return (
        <Box sx={style} className="w-full box-border md:w-96 max-h-screen overflow-y-auto flex justify-center items-center ">
            <div className="p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md">
                <Typography variant="h6">Registrati</Typography>
                <Divider className="mt-4 ,b-4" />
                {
                    (isLoading) ? (
                        <div className="h-48">
                            <Loading />
                        </div>
                    ) : (
                        <div className="p-2 grid grid-cols-2 gap-4">
                            <TextField
                                className='col-span-2 md:col-span-1'
                                name="first_name"
                                label='Nome*'
                                variant="standard"
                                type="text"
                                value={firstName}
                                onChange={(event) => setFirstName(event.target.value)}

                                error={errors?.firstName != null}
                                helperText={errors?.firstName}
                            />
                            <TextField
                                className='col-span-2 md:col-span-1'
                                name="last_name"
                                label='Cognome*'
                                variant="standard"
                                type="text"
                                value={lastName}
                                onChange={(event) => setLastName(event.target.value)}

                                error={errors?.lastName != null}
                                helperText={errors?.lastName}
                            />
                            <TextField
                                className='col-span-2'
                                name="email"
                                label='Email*'
                                variant="standard"
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}

                                error={errors?.email != null}
                                helperText={errors?.email}
                            />
                            <TextField
                                className='col-span-2'
                                name="mobile"
                                label='Telefono'
                                variant="standard"
                                type="text"
                                value={mobile}
                                onChange={(event) => setMobile(event.target.value)}

                                error={errors?.mobile != null}
                                helperText={errors?.mobile}
                            />
                            <TextField
                                className='col-span-2 md:col-span-1'
                                name="password"
                                label='Password*'
                                variant="standard"
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}

                                error={errors?.password != null}
                                helperText={errors?.password}
                            />
                            <TextField
                                className='col-span-2 md:col-span-1'
                                name="password-confirm"
                                label='Conferma password*'
                                variant="standard"
                                type="password"
                                value={passwordConfirmation}
                                onChange={(event) => setPasswordConfirmation(event.target.value)}

                                error={errors?.passwordConfirmation != null}
                                helperText={errors?.passwordConfirmation}
                            />
                            <div className="col-span-2 flex gap-2 items-center mt-4">
                                <FormControlLabel control={<Switch checked={isPrivacyPolicyAccepted} onChange={(event) => setIsPrivacyPolicyAccepted(event.target.checked)} />} label={<>
                                    Acconsento al trattamento dei miei dati personali e dichiaro di aver preso visione della&nbsp;
                                    <Link key="privacy-policy" href="https://www.washupitalia.it/privacy-policy" underline="hover" target="_blank" >Privacy Policy</Link>.
                                </>} />
                            </div>
                            { (errors?.terms != null) && (
                                <Alert className="col-span-2" severity="error">{errors?.terms}.</Alert>
                            )}
                            { (failed) && (
                                <Alert className="col-span-2" severity="error">Registrazione fallita, l'email inserita è già in uso.</Alert>
                            )}
                            <div className="col-span-2 flex flex-col gap-2">
                                <div className="h-8"></div>
                                <WashupElevatedButton onClick={() => tryRegister()}>Registrati</WashupElevatedButton>
                                <Divider />
                                <Typography variant="subtitle2" className="text-center">Hai già un account?</Typography>
                                <WashupElevatedButton onClick={() => setShowLoginModal("login")}>Accedi</WashupElevatedButton>
                                <div className="h-8"></div>
                                <WashupOutlinedButton onClick={() => setShowLoginModal()}>Chiudi</WashupOutlinedButton>
                            </div>
                        </div>
                    )
                }
            </div>
        </Box>
    )

}
export default RegisterModal;