import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

export const WashupHeader = (props) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div>
                <div className={"bg-gradient-to-br from-washup-400 via-washup-500 to-washup-600"}>
                    <div className={"p-16 pb-32 bg-cover bg-center"} style={props.bannerStyle}>
                        {props.banner}
                    </div>
                </div>
                <div className="px-2 md:px-0">
                    <Container maxWidth="md" className="w-80 bg-white m-auto p-4 rounded-3xl drop-shadow-md" style={{ marginTop: -100 }}>
                        {props.children}
                    </Container>
                </div>
            </div>
        </Box>
    )
}

export const WashupHeaderSlim = (props) => {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div>
                <div className={"bg-gradient-to-br from-washup-400 via-washup-500 to-washup-600"}>
                    <div className={"p-8 md:p-16 pb-22 bg-cover bg-center"} style={props.bannerStyle}>
                        {props.banner}
                    </div>
                </div>
                <div className="px-2 md:px-0">
                    <Container maxWidth="md" className="w-80 bg-white m-auto p-4 rounded-3xl drop-shadow-md" style={{ marginTop: -50 }}>
                        {props.children}
                    </Container>
                </div>
            </div>
        </Box>
    )
}

