import { Item } from "./item";

export class LaundryItem{

    constructor(data){
        this.id = data.id;
        this.finalPrice = data.final_price;

        this.item = new Item(data.item);
    }

    //get commission() { return ((this.price * this.item.commission / 100) * 10).ceil() / 10; }
    //get finalPrice() { return this.price + this.commission; }
}

/*
class LaundryItem {
  late int itemId;
  late double price;

  late Item item;

  double get commission => ((price * item.commission / 100) * 10).ceil() / 10;
  double get finalPrice => price + commission;

  LaundryItem.fromJson(Map<String, dynamic> json) {
    itemId = json["item_id"];
    price = json["price"];

    item = Item.fromJson(json["item"]);
  }

  Map<String, dynamic> toJson() => {
        'item_id': itemId,
        'price': price,
      };
}
*/