import { Card, CardContent, Divider, Radio, TextField, Typography } from "@mui/material";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';
import { useContext, useEffect, useState } from "react";
import WashupContext from "../../../../providers/WashupProvider";
import UserAddress from "../../../../_models/user-address";
import Loading from "../../../_components/loading";
import React from "react";


const CartStepAddressGuest = (props) => {
    const { user, cart, setOrderAddress } = useContext(WashupContext);


    const [isLoading, setIsLoading] = useState(false);

    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");


    useEffect(() =>  {
        setAddress(props.type === "pickup" ? cart.pickupAddress?.address : cart.deliveryAddress?.address);
        setPostalCode(props.type === "pickup" ? cart.pickupAddress?.postalCode : cart.deliveryAddress?.postalCode);
        setCity(props.type === "pickup" ? cart.pickupAddress?.city : cart.deliveryAddress?.city);
        setProvince(props.type === "pickup" ? cart.pickupAddress?.province : cart.deliveryAddress?.province);
    },[cart]);


    const setNewAddress = async () => {
        setIsLoading(true);
        var userAddress = new UserAddress({
            address: address,
            postal_code: postalCode,
            city: city,
            province: province,
        });
        await setOrderAddress(cart, props.type, userAddress);
        setIsLoading(false);
        props.setActiveStep(props.activeStep + 1);
    }

    const isComplete = () => {
        if(address == null || address === "") return false;
        if(postalCode == null || postalCode.length !== 5) return false;
        if(city == null || city === "") return false;
        if(province == null || province === "") return false;
        return true;
    }

    return (
        <>
            <Card className="mt-4">
                {(isLoading) ? (
                    <CardContent >
                        <div className="p-8">
                            <Loading />
                        </div>
                    </CardContent>
                ) : (
                    <CardContent>
                        <TextField
                            className='w-full'
                            label='Indirizzo*'
                            name="address"
                            variant="standard"
                            value={address}
                            onChange={(event) => setAddress(event.target.value)}
                        />
                        <div className='flex flex-col md:flex-row gap-4 mb-8 mt-4'>
                            <TextField
                                label='CAP*'
                                name="postal-code"
                                variant="standard"
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                            />
                            <TextField
                                className='w-full'
                                label='Città*'
                                name="city"
                                variant="standard"
                                value={city}
                                onChange={(event) => setCity(event.target.value)}
                            />
                            <TextField
                                label='Provincia*'
                                name="province"
                                variant="standard"
                                value={province}
                                onChange={(event) => setProvince(event.target.value)}
                            />
                        </div>
                    </CardContent>
                )}
            </Card>
            <div className='flex justify-end mt-8 gap-4'>
                <WashupOutlinedButton onClick={() => props.setActiveStep(props.activeStep - 1)}>Indietro</WashupOutlinedButton>
                <WashupElevatedButton onClick={() => setNewAddress()} disabled={!isComplete() || isLoading}>Avanti</WashupElevatedButton>
            </div>
        </>
    );
}
export default CartStepAddressGuest;