import { Laundry } from "./laundry";
import OrderEntry from "./order-entry";
import moment from 'moment';
import OrderStatus from "./order-status";
import UserAddress from "./user-address";
import OrderPayment from "./order-payment";
import { Coupon } from "./coupon";

class Order {
    constructor(data){
        this.id = data.id;
        this.guestId = data.guest_id;
        this.deliveryPrice = data.delivery_price ?? 4.00;
        this.additionalInfos = data.additional_infos;

        this.createdAt = moment(new Date(data.created_at)).format('DD/MM/YYYY HH:mm');
        this.updatedAt = moment(new Date(data.updated_at)).format('DD/MM/YYYY HH:mm');
        this.completedAt = moment(new Date(data.completed_at)).format('DD/MM/YYYY HH:mm');

        this.pickupAddressId = data.user_pickup_address_id;
        this.deliveryAddressId = data.user_delivery_address_id;

        this.itemsFinalPrice = data.items_final_price;

        this.laundry = data.laundry ? new Laundry(data.laundry) : null;

        this.entries = data.entries?.map((e) => new OrderEntry(e)) ?? [];
        this.entrtiesCount = 0;
        data.entries.forEach((e) => {
            this.entrtiesCount += e.quantity;
        }); 

        this.currentStatus = data.status_current ? new OrderStatus(data.status_current) : null;
        this.statusHistory = data.status_updates != null ? data.status_updates.map((e) => new OrderStatus(e)) : [];


        this.pickupAddress = data.user_pickup_address; // ? new UserAddress(data.pickup_address) : undefined;
        this.deliveryAddress = data.user_delivery_address; // ? new UserAddress(data.delivery_address) : undefined;

        this.coupon = data.coupon ?  new Coupon(data.coupon) : undefined;

        this.payments = data.payments?.map((e) => new OrderPayment(e)) ?? [];
        //this.pickup_address = data.pickup_address;
        //this.delivery_address = data.delivery_address;
    }

    getCouponDiscount = () => {
        if(this.coupon != null){
            if(this.coupon.amount != null){
                return Number(this.coupon.amount);
            }
            if(this.coupon.percentage != null){
                return this.getTotalPrice(false) * Number(this.coupon.percentage) / 100;
            }
        }
        return 0;
    }

    getTotalPrice = (includeAll = true) => {
        var total = 0;
        this.entries.forEach((e) => {
            total += Number(e.getTotalPrice(this.laundry));
        });
        if(includeAll){
            total -= this.getCouponDiscount();
            if(total < 0) total = 0;
            total += Number(this.deliveryPrice);
        }
        return total;
    }
}
export default Order;