// @ts-nocheck
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, Menu } from '@mui/icons-material';
import WashupContext from '../../providers/WashupProvider';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import React from 'react';
import { useContext } from "react";
import WashupCartControl from './cart-control';

const WashupAppBar = () => {
    const { user, setShowDrawer, setShowMenuDrawer } = useContext(WashupContext);
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" className='py-2 md:p-2' color='inherit'>
                <Toolbar>
                    <Button onClick={() => navigate('/')} className='m-4'>
                        <img src="/images/washup_web_side.png" className='h-12 md:h-14' alt="WashUp" />
                    </Button>

                    <div className='flex-grow'></div>
                    
                    <div className='flex md:hidden gap-4'>

                        <Divider orientation='vertical' />
                        
                        
                        <WashupCartControl />

                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            onClick={() => setShowDrawer(true)}
                            color={ (user == null) ? "disabled" : "primary"}
                        >
                            <AccountCircle fontSize='large' />
                        </IconButton>

                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            onClick={() => setShowMenuDrawer(true)}
                            color={ (user == null) ? "disabled" : "primary"}
                        >
                            <Menu fontSize='large' />
                        </IconButton>
                    </div>
                    
                    <div className='hidden md:flex gap-4'>
                        <Button onClick={() => navigate('/')} className=''>
                            HOME
                        </Button>
                        
                        <Button onClick={() => navigate('/news')} className=''>
                            News
                        </Button>
                        
                        <Button onClick={() => navigate('/page/lavora-con-noi')} className=''>
                            Lavora con noi
                        </Button>

                        <Divider orientation='vertical' />
                        
                        <WashupCartControl />

                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            onClick={() => setShowDrawer(true)}
                            color={ (user == null) ? "disabled" : "primary"}
                        >
                            <AccountCircle fontSize='large' />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default WashupAppBar;