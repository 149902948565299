// @ts-nocheck
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, ShoppingCart, Menu } from '@mui/icons-material';
import WashupContext from '../../providers/WashupProvider';
import { useNavigate } from 'react-router-dom';
import { Badge, ClickAwayListener, Divider, MenuItem, Paper, Popper, Typography } from '@mui/material';
import React from 'react';
import { useContext, useState } from "react";
import { WashupElevatedButton, WashupOutlinedButton } from 'apps/_components/button';

const WashupCartControl = () => {
    const { cart } = useContext(WashupContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const goToCart = () => {
        setAnchorEl(null);
        navigate('/laundry/' + cart?.laundry.slug);
    }
    const goToCheckout = () => {
        setAnchorEl(null);
        navigate("/cart");
    }

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                aria-controls={anchorEl != null ? 'cart-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl != null ? 'true' : undefined}

                disabled = {cart == null || cart.entrtiesCount === 0}
                onClick={(event) => setAnchorEl(anchorEl != null ? null : event.currentTarget)/*navigate('/laundry/' + cart?.laundry.slug)*/}
                color={ (cart == null || cart.entrtiesCount === 0) ? "disabled" : "primary"}
            >
                <Badge badgeContent={cart?.entrtiesCount} color="success">
                    <ShoppingCart fontSize='large' />
                    </Badge>
            </IconButton>
            <Popper
                style={{ zIndex: 99999 }}
                id="cart-menu"
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Paper className='flex flex-col gap-2 p-2 rounded-3xl shadow-md'>
                        <div className='flex items-baseline gap-2 p-2'>
                            <Typography variant='h6'>Totale</Typography>
                            <div className='flex-grow'></div>
                            <Typography variant='h6'>€</Typography>
                            <Typography variant='h6'>{Number(cart?.getTotalPrice()).toFixed(2)}</Typography>
                        </div>
                        <Divider />
                        <WashupOutlinedButton onClick={() => goToCart()} >Continua lo shopping</WashupOutlinedButton>
                        <WashupElevatedButton onClick={() => goToCheckout()}>Completa ordine</WashupElevatedButton>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default WashupCartControl;