import { useContext } from "react";
import { Box, Divider, Typography } from "@mui/material";
import WashupContext from "providers/WashupProvider";
import { WashupElevatedButton, WashupOutlinedButton } from "apps/_components/button";
import { useNavigate } from "react-router-dom";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

const OrderDiscardModal = (props) => {
    const { cart, setShowDiscardCartModal } = useContext(WashupContext);
    const navigate = useNavigate();


    const goToCart = () => {
        setShowDiscardCartModal(false);
        navigate("/laundry/" + cart.laundry.slug);
    }

    return (
        <Box sx={style}>
            <div className="w-96 p-8 bg-white m-auto p-4 rounded-3xl drop-shadow-md">
                <Typography variant="h6">Carrello già esistente!</Typography>
                <Divider className="mt-4 ,b-4" />
                    <div className="p-4 flex flex-col gap-4 items-stretch">
                        <Typography variant="body1">ATTENZIONE: Hai già un carrello con <strong>{ cart.entrtiesCount }</strong> elementi presso la lavanderia <strong>{ cart.laundry.name }</strong>.</Typography>
                        <Typography variant="body1">Creando un nuovo carrello, quello precedente verrà eliminato, continuare?</Typography>
                        <Divider />
                        <WashupElevatedButton onClick={() => goToCart()}>CONTINUA LO SHOPPING</WashupElevatedButton>
                        <WashupElevatedButton onClick={() => props.onDiscard(true)}>CREA UN NUOVO CARRELLO</WashupElevatedButton>
                        <WashupOutlinedButton onClick={() => props.onDiscard(false)}>ANNULLA</WashupOutlinedButton>
                    </div>
            </div>
        </Box>
    )

}
export default OrderDiscardModal;